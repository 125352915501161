import { useTranslation } from "react-i18next"
import classNames from "classnames"

const ColumnHead = ({ title, sort, order, order_by, th_class, onOrder = f => f }) => {
    const {t} = useTranslation()
    const gcls = classNames("btn-group", { 
        'btn-group--asc': order_by === sort && order === 'asc',
        'btn-group--desc': order_by === sort && order === 'desc',
    })
    return (
        <th className={th_class}>
            <div className="table__cell-wrapper">
                {title}
                {sort &&
                <div className={gcls}>
                    <button type="button" className="btn btn--order btn--asc" onClick={() => onOrder(sort, 'asc')}>
                        <span className="visually-hidden">{t('filters.orderAsc')}</span>
                        <span aria-hidden="true" className="icon icon--smal icon--arrow-drop-up"></span>
                    </button>
                    <button type="button" className="btn btn--order btn--desc" onClick={() => onOrder(sort, 'desc')}>
                        <span className="visually-hidden">{t('filters.orderDesc')}</span>
                        <span aria-hidden="true" className="icon icon--smal icon--arrow-drop-down"></span>
                    </button>
                </div>
                }
            </div>
        </th>
    )
}

export default ColumnHead