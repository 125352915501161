
import { getHeaders, apiCall } from 'services/api'

export const saveConfig = ({context, data}) => {

    const { userId, s, env } = context
    const {
      folder_property_message,
      expire_garbage_value,
      expire_garbage_options,
      can_manage_users_added,
      can_manage_users_deleted,
      classroom_types,
      classroom_types_deleted,
      nodes_lectius,
      nodes_finalitzacio,
      nodes_esborrat
    } = data


    const body = {
      userId,
      s,
      folder_property_message,
      expire_garbage_value,
      expire_garbage_options,
      can_manage_users_added,
      can_manage_users_deleted,
      classroom_types: classroom_types.map((type) => {
        return {id: type.isNew ? -1 : type.id, key: type.key, expire: type.expire}
      }),
      classroom_types_deleted,
      nodes_lectius,
      nodes_finalitzacio,
      nodes_esborrat
    }

    const url = process.env.REACT_APP_DEMO_MODE === '1' ? 
      `${env.consumer_api_base}/${process.env.REACT_APP_DEMO_SUCCESS}` : 
      `${env.consumer_api_base}/${process.env.REACT_APP_API_CONFIG}`

    const headers = { ...getHeaders(context.lang), "Content-Type": "application/json" };
    const request = new Request(url, {
      method: "POST",
      credentials: "include",
      headers: headers,
      body: JSON.stringify(body),
    })
    return apiCall(request)
}
