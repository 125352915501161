
import queryString from 'query-string';
import { getHeaders, apiCall } from 'services/api'

export const getGroups = ({
  lang,
  env,
  page,
  size,
  order,
  order_by,
  code,
  sync_status,
  group_name,
  semester,
  classroom_type,
  start_date_group,
  end_date_group,
  start_date_classroom,
  end_date_classroom
}) => {
  const headers = getHeaders(lang);
  const params = {
    size: size,
    page: page,
    lang: lang,
    sort: order_by + ',' + order
  }
  if (code !== "") params.code = code;
  if (sync_status !== "") params.sync_status = sync_status;
  if (group_name !== "") params.group_name = group_name;
  if (semester !== "") params.semester = semester;
  if (classroom_type !== "") params.classroom_type = classroom_type;
  if (start_date_group) params.start_date_group = start_date_group;
  if (end_date_group) params.end_date_group = end_date_group;
  if (start_date_classroom) params.start_date_classroom = start_date_classroom;
  if (end_date_classroom) params.end_date_classroom = end_date_classroom;

  const request = new Request(
    `${env.consumer_api_base}/${process.env.REACT_APP_API_GROUPS}?${queryString.stringify(params)}`,
    //`${env.consumer_api_base}/error`,
    {
      method: "GET",
      credentials: "include",
      headers: headers,
    }
  );
  return apiCall(request);
};