
export const checkEngine = (env) => {
  const request = new Request(
    `${env.consumer_api_base}/${process.env.REACT_APP_API_CHECK}`,
    {
      method: "GET",
      credentials: "include"
    }
  )
  return fetch(request)
}

export const checkQueues = (env) => {
  const request = new Request(
    `${env.queues_api_base}/${process.env.REACT_APP_API_CHECK}`,
    {
      method: "GET",
      credentials: "include"
    }
  )
  return fetch(request)
}