import { useTranslation } from "react-i18next"
import { format, isValid, parseISO } from "date-fns"

import { Collapse } from "react-bootstrap"
import * as c from "config/constants"

import IconTooltip from "components/IconTooltip"
import ColumnHead from "components/ColumnHead"

import { useSessionContext } from "context/SessionContext"
import { useMediaContext } from 'context/MediaContext'
import { useStickyTable } from "hooks/useStickyTable"




const CollapsibleRow = ({ id, type, date, result, detailType, detailMessage, isExpanded = false, onExpandCollapse = f => f }) => {
  const { t } = useTranslation()
  const { dateFormat } = useSessionContext()

  if (!id) return null

  const dateParsed = parseISO(date)
  const moreTitle = isExpanded ? t("actions.collapse") : t("actions.expand")
  const moreIcon = isExpanded ? "icon--minus-small" : "icon--plus-small" 

  return (
    <>
      <tr>
        <td>
          <a href="/#" role="button"
            className={`btnlink btnlink--icon btnlink--expand-collapse p-2 ml-1`}
            onClick={(e) => { e.preventDefault(); onExpandCollapse(id) }}
            title={moreTitle}
            aria-label={moreTitle}
            aria-controls={`collapse-historic-${id}`}
            aria-expanded={isExpanded}
          >
            <span
              className={`icon ${moreIcon} icon--small`}
              aria-hidden="true"
            ></span>
          </a>
        </td>
        <td>{type}</td>
        <td>{isValid(dateParsed) ? format(dateParsed, `${dateFormat} HH:mm`) : "---"}</td>
        <td>
          {result === 1 ? (
            <IconTooltip
              tooltip={t("common.movementOk")}
              id={`tooltip_result_${id}`}
              icon="icon--checkmark icon--small color-success"
              placement="top"
            />
          ) : (
            <IconTooltip
              tooltip={t("common.movementError")}
              id={`tooltip_result_${id}`}
              icon="icon--alert-full icon--small color-error"
              placement="top"
            />
          )}
        </td>
      </tr>
      <tr className="collapsible-row">
        <td className="bg-grey-lighter"></td>
        <td className="bg-grey-lighter" colSpan="3">
          <Collapse in={isExpanded}>
            <div id={`collapse-historic-${id}`} className="collapsible-content">
                <div className={`py-3 color-${detailType}`}>{detailMessage}</div>
            </div>
          </Collapse>
        </td>
      </tr>
    </>
  )
}

const HistoricTable = ({
  rows = [],
  order = c.HISTORIC_DEFAULT_ORDER,
  order_by = c.HISTORIC_DEFAULT_ORDER_BY,
  onChangeOrder = f => f,
  onExpandCollapse = f => f
}) => {

  const { t } = useTranslation()
  const { smView } = useMediaContext()
  const { ref, fixedRef, normalRef, wrapperRef, fixedWrapperRef } = useStickyTable()

  const columns = [
    {
      id: 1,
      title: t("columns.moreInfo"),
      width: smView ? "17%" : "12%"
    },
    {
      id: 2,
      title: t("columns.movementType"),
      sort: "type"
    },
    {
      id: 3,
      title: t("columns.dateTime"),
      sort: "date",
      width: "25%"
    },
    {
      id: 4,
      title: t("columns.result"),
      sort: "result",
      width: "15%"
    }
  ]

  return (
    <div className="table-responsive table-sticky" ref={wrapperRef}>
      <div className="table-sticky-fixed" ref={fixedRef} aria-hidden="true">
        <div className="container table-sticky-container">
          <div className="table-sticky-overflow" ref={fixedWrapperRef}>
            <table className="table table--borders table--dynamic table--historic">
              <colgroup>
                {columns.map((item) => (
                  <col
                    key={item.id}
                    style={{ width: item.width ? item.width : null }}
                  />
                ))}
              </colgroup>
              <thead className="table__header">
                <tr>
                  {columns.map((item) => (
                    <ColumnHead
                      title={item.title}
                      sort={item.sort}
                      key={item.id}
                      order={order}
                      order_by={order_by}
                      onOrder={onChangeOrder}
                    />
                  ))}
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </div>
      <div className="table-sticky-normal" ref={normalRef}>
        <div className="sticky-ref" ref={ref}></div>
        <table className="table table--borders table--dynamic table--historic">
          <caption className="visually-hidden">{t("app.historicTitle")}</caption>
          <colgroup>
            {columns.map((item) => (
              <col
                key={item.id}
                style={{ width: item.width ? item.width : null }}
              />
            ))}
          </colgroup>
          <thead className="table__header">
            <tr>
              {columns.map((item) => (
                <ColumnHead
                  title={item.title}
                  sort={item.sort}
                  key={item.id}
                  order={order}
                  order_by={order_by}
                  onOrder={onChangeOrder}
                />
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td colSpan="4">
                  <span className="text-muted">{t("common.noResults")}</span>
                </td>
              </tr>
            ) : (
              rows.map((item) => {
                const { id, type, date, result, detail, isExpanded } = item;
                return (
                  <CollapsibleRow
                    key={id}
                    id={id}
                    type={type}
                    date={date}
                    result={result}
                    isExpanded={isExpanded}
                    detailType={detail.type}
                    detailMessage={detail.message}
                    onExpandCollapse={onExpandCollapse}
                  />
                )
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default HistoricTable;
