
import queryString from 'query-string';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ca from 'date-fns/locale/ca';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-GB';

const parsed = queryString.parse(window.location.search);
const lang = (parsed.lang) ? parsed.lang : 'ca';

switch(lang){
  case 'es':
    registerLocale('es', es)
    setDefaultLocale(es)
    break
  case 'en':
    registerLocale('en', en)
    setDefaultLocale(en)
    break
  default:
    registerLocale('ca', ca)
    setDefaultLocale(ca)
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: lang,    
    fallbackLng: lang,
    debug: false, // process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false
    },
    backend: {
      loadPath: `${process.env.REACT_APP_LOCALES_PATH}/{{lng}}/{{ns}}.json`
    }
  })

export default i18n;