import { Component } from "react";

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: { message: "", stack: "" },
      info: { componentStack: "" }
    }
  }

  static getDerivedStateFromError = error => {
    return { hasError: true };
  }

  componentDidCatch = (error, info) => {
    this.setState({ hasError: true, error, info });
  }

  render() {
    const { hasError, error, info } = this.state
    const { children } = this.props
    if (hasError) {
      console.log(error, info)
      return (
        <main id="main" className="main with-sticky-header" role="main">
          <div className="container">
            <h2 className="h4">Quelcom ha fallat :(</h2>
          </div>
        </main>
      )
    }
    return children; 
  }

}
