import { useState, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import * as c from 'config/constants'

import InputDateRange from "components/InputDateRange"
import ConditionalWrapper from "components/ConditionalWrapper"

import { useMediaContext } from "context/MediaContext"
import { useGroupsFiltersState, useGroupsFiltersDispatch } from 'context/GroupsContext'
import { useFilters } from 'hooks/useFilters'



const GroupsFilters = ( {onSubmit = f => f} ) => {
    const {t} = useTranslation()

    const {
        sync_status,
        code,
        group_name,
        semester,
        classroom_type,
        start_date_classroom,
        end_date_classroom,
        start_date_group,
        end_date_group,
    } = useGroupsFiltersState();


    const { smView, xlView } = useMediaContext()
    const [gg, setGG] = useState(group_name)
    const [domainCode, setDomainCode] = useState(code)
    const [filterSync, setSync] = useState(sync_status)
    const [filterSemester, setSemester] = useState(semester)
    const [filterType, setType] = useState(classroom_type)
    const [filterStartClass, setStartClass] = useState(start_date_classroom)
    const [filterEndClass, setEndClass] = useState(end_date_classroom)
    const [filterStartGroup, setStartGroup] = useState(start_date_group)
    const [filterEndGroup, setEndGroup] = useState(end_date_group)

    const gfDispatch = useGroupsFiltersDispatch()
    const { filtersState } = useFilters()

    useEffect(
      function () {
        console.log(start_date_group)
        setDomainCode(code)
        setGG(group_name)
        setSync(sync_status)
        setSemester(semester)
        setType(classroom_type)
        setStartClass(start_date_classroom)
        setEndClass(end_date_classroom)
        setStartGroup(start_date_group)
        setEndGroup(end_date_group)
      }, [code,group_name,sync_status,semester,classroom_type,start_date_classroom,end_date_classroom,start_date_group,end_date_group]
    )

    const handleSubmit = (evt) => {
        evt.preventDefault()
        gfDispatch({ type: c.SET_SEARCH, payload: {
          code: domainCode,
          group_name: gg,
          sync_status: filterSync,
          semester: filterSemester,
          classroom_type: filterType,
          start_date_classroom: filterStartClass,
          end_date_classroom: filterEndClass,
          start_date_group: filterStartGroup,
          end_date_group: filterEndGroup
        } })
    }

    const handleChangeSync = (evt) => {
      setSync(evt.target.value)
      //gfDispatch({ type: c.SET_SYNC, payload: evt.target.value })
    }

    const handleChangeGroupStart = (date) => {
        if(date !== start_date_group)
            // gfDispatch({ type: c.SET_START_DATE_GROUP, payload: date })
            setStartGroup(date)
    }

    const handleChangeGroupEnd = (date) => {
        if(date !== end_date_group)
          setEndGroup(date)
            //gfDispatch({ type: c.SET_END_DATE_GROUP, payload: date })
    }

    const handleChangeClassroomStart = (date) => {
        if(date !== start_date_classroom)
          setStartClass(date)
            //gfDispatch({ type: c.SET_START_DATE_CLASSROOM, payload: date })
    }

    const handleChangeClassroomEnd = (date) => {
        if(date !== end_date_classroom)
          setEndClass(date)
            //gfDispatch({ type: c.SET_END_DATE_CLASSROOM, payload: date })
    }

    const handleChangeSemester = (evt) => {
      setSemester(evt.target.value)
        //gfDispatch({ type: c.SET_SEMESTER, payload: evt.target.value })
    }

    const handleChangeClassroom = (evt) => {
      setType(evt.target.value)
        //gfDispatch({ type: c.SET_CLASSROOM_TYPE, payload: evt.target.value })
    }

    const handleChangeCode = (evt) => {
        setDomainCode(evt.target.value)
    }

    const handleChangeGroup = (evt) => {
        setGG(evt.target.value)
    }

    const btn_icon_cls = classNames("icon icon--search-full", { 'icon--before': !xlView })
    const btn_lbl_cls = classNames({ 'visually-hidden': xlView })

    return (
      <form
        className="form--inverse form--filters-groups mb-3 mb-md-2"
        noValidate
        onSubmit={handleSubmit}
      >
        <div className="d-xl-flex flex-column flex-xl-row justify-content-between align-items-end">

          <ConditionalWrapper
            condition={!xlView}
            wrapper={children => <div className="d-md-flex flex-row justify-content-between align-items-end">{children}</div>}
          >

            <div className="form-group">
              <label htmlFor="filter-sync" className="select--custom">
                <span className="label">{t("filters.sync")}</span>
                <select
                  id="filter-sync"
                  className="form-item"
                  onChange={handleChangeSync}
                  value={filterSync}
                >
                  <option value="">{t("actions.select")}</option>
                  <option value="2">{t("common.ok")}</option>
                  <option value="1">{t("common.processing")}</option>
                  <option value="0">{t("common.error")}</option>
                </select>
              </label>
            </div>

            <div className="form-group form-group--range">
              <fieldset>
                <legend className="label" id="lbl-interval-group">{t("filters.intervalGroup")}</legend>
                <InputDateRange
                  name="filter-group-interval"
                  start={!!filterStartGroup ? new Date(filterStartGroup) : null}
                  end={!!filterEndGroup ? new Date(filterEndGroup) : null}
                  onChangeStart={handleChangeGroupStart}
                  onChangeEnd={handleChangeGroupEnd}
                  labelledby="lbl-interval-group"
                />
              </fieldset>
            </div>

            {(xlView || smView) &&
              <div className="form-group form-group--range">
                <fieldset>
                  <legend className="label" id="lbl-interval-classroom">{t("filters.intervalAula")}</legend>
                  <InputDateRange
                    name="filter-classroom-interval"
                    start={filterStartClass ? new Date(filterStartClass) : null}
                    end={filterEndClass ? new Date(filterEndClass) : null}
                    onChangeStart={handleChangeClassroomStart}
                    onChangeEnd={handleChangeClassroomEnd}
                    labelledby="lbl-interval-classroom"
                  />
                </fieldset>
              </div>
            }



          </ConditionalWrapper>

          <ConditionalWrapper
            condition={!xlView}
            wrapper={children => <div className="d-md-flex flex-row justify-content-between align-items-end">{children}</div>}
          >

            {!(xlView || smView) &&
              <div className="form-group form-group--range">
                <fieldset>
                  <legend className="label" id="lbl-interval-classroom">{t("filters.intervalAula")}</legend>
                  <InputDateRange
                    name="filter-classroom-interval"
                    start={filterStartClass ? new Date(filterStartClass) : null}
                    end={filterEndClass ? new Date(filterEndClass) : null}
                    onChangeStart={handleChangeClassroomStart}
                    onChangeEnd={handleChangeClassroomEnd}
                    labelledby="lbl-interval-classroom"
                  />
                </fieldset>
              </div>
            }

            <div className="form-group">
              <label htmlFor="filter-code">{t("filters.code")}</label>
              <input
                type="text"
                id="filter-code"
                placeholder={t("filters.domainCode")}
                className="form-item"
                value={domainCode}
                onChange={handleChangeCode}
              />
            </div>

            <div className="form-group">
              <label htmlFor="filter-group">{t("filters.group")}</label>
              <input
                type="text"
                id="filter-group"
                placeholder={t("columns.groupName")}
                className="form-item"
                value={gg}
                onChange={handleChangeGroup}
              />
            </div>

            <div className="form-group">
              <label htmlFor="filter-semester" className="select--custom">
                <span className="label">{t("filters.semester")}</span>
                <select
                  id="filter-semester"
                  className="form-item"
                  value={filterSemester}
                  onChange={handleChangeSemester}
                  disabled={filtersState.isLoading}
                >
                  <option value="">{t("actions.select")}</option>
                  {
                    filtersState.isLoading ? null :
                    filtersState.semesters.map((item) => {
                      return <option value={item.id} key={item.id}>{item.name}</option>
                    })
                  }
                </select>
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="filter-type" className="select--custom">
                <span className="label">{t("filters.aulaType")}</span>
                <select
                  id="filter-type"
                  className="form-item"
                  value={filterType}
                  onChange={handleChangeClassroom}
                  disabled={filtersState.isLoading}
                >
                  <option value="">{t("actions.select")}</option>
                  {
                    filtersState.isLoading ? null :
                    filtersState.classroom_types.map((item) => {
                      return <option value={item.key} key={item.id}>{item.key}</option>
                    })
                  }
                </select>
              </label>
            </div>

            <div className="form-group form-group--send">
              <button
                type="submit"
                className="btn btn--primary btn--block btn--save"
                title={t("actions.search")}
              >
                <span
                  className={btn_icon_cls}
                  aria-hidden="true"
                ></span>
                <span className={btn_lbl_cls}>{t("actions.search")}</span>
              </button>
            </div>

          </ConditionalWrapper>

        </div>
      </form>
    )
}

export default GroupsFilters
