import { getHeaders, apiCall } from 'services/api'

export const getConfig = ({ lang, env }) => {
  const headers = getHeaders(lang);
  const request = new Request(
    `${env.consumer_api_base}/${process.env.REACT_APP_API_CONFIG}`,
    {
      method: "GET",
      credentials: "include",
      headers: headers,
    }
  );
  return apiCall(request);
};