import { useEffect } from "react"

export default function ScrollToTopSmooth() {

  useEffect(() => {
    let timer
    timer = setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    }, 300)
    return () => timer && clearTimeout(timer)
  })

  return null
}