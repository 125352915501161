import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { format, isValid, parseISO } from "date-fns"

import * as c from "config/constants"

import IconTooltip from "components/IconTooltip"
import GenericTooltip from "components/GenericTooltip"
import ColumnHead from "components/ColumnHead"

import { useSessionContext } from "context/SessionContext"
import { useAppDispatch } from "context/AppContext"
import { useGroupsFiltersState, useGroupsFiltersDispatch } from "context/GroupsContext"
import { useStickyTable } from "hooks/useStickyTable"

const SyncStatus = ({ id, sync_status, sync_message }) => {
  const { t } = useTranslation();
  switch (sync_status) {
    case 2:
      return (
        <IconTooltip
          tooltip={sync_message ? sync_message : t("common.syncOk")}
          id={`tooltip_sync_${id}`}
          icon="icon--checkmark icon--small color-success"
          placement="top"
        />
      )
    case 1:
      return (
        <IconTooltip
          tooltip={sync_message ? sync_message : t("common.syncProcess")}
          id={`tooltip_sync_${id}`}
          icon="icon--clock-full icon--small color-primary"
          placement="top"
        />
      )
    default:
      return (
        <IconTooltip
          tooltip={sync_message ? sync_message : t("common.syncError")}
          id={`tooltip_sync_${id}`}
          icon="icon--alert-full icon--small color-error"
          placement="top"
        />
      )
  }
}

const GroupsTable = ({ cols = [], rows = [], onResync = (f) => f }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { ref, fixedRef, normalRef, wrapperRef, fixedWrapperRef } = useStickyTable()
  const { dateFormat } = useSessionContext()
  const { order, order_by, columns } = useGroupsFiltersState()
  const gfDispatch = useGroupsFiltersDispatch()
  const appDispatch = useAppDispatch()

  const handleChangeOrder = (orderBy, orderDir) => {
    gfDispatch({
      type: c.SET_ORDER,
      payload: { order_by: orderBy, order: orderDir }
    })
  }

  const handleClickGroup = (group_id, group_name, evt) => {
    evt.preventDefault()
    appDispatch({ type: c.SET_GROUP, payload: { group_id, group_name } })
    history.push(`/groups/${group_id}`)
  }

  return (
    <div className="table-responsive table-sticky" ref={wrapperRef}>
      <div className="table-sticky-fixed" ref={fixedRef} aria-hidden="true">
        <div className="container table-sticky-container">
          <div className="table-sticky-overflow" ref={fixedWrapperRef}>
            <table className="table table--borders table--dynamic table--groups">
                <colgroup>
                {cols.map((item, index) => {
                  if(columns[index] && !columns[index].show) return null
                  return(
                    <col
                    key={item.id}
                    style={{ width: item.width ? item.width : null }}
                    />
                  )
                })}
                </colgroup>
                <thead className="table__header">
                <tr>
                    {cols.map((item, index) => {
                      if(columns[index] && !columns[index].show) return null
                      return(
                        <ColumnHead
                            title={item.title}
                            sort={item.sort}
                            key={item.id}
                            order={order}
                            order_by={order_by}
                            th_class="text-small"
                            onOrder={handleChangeOrder}
                        />
                      )
                    })}
                </tr>
                </thead>
            </table>
          </div>
        </div>
      </div>
      <div className="table-sticky-normal" ref={normalRef}>
        <div className="sticky-ref" ref={ref}></div>
        <table className="table table--borders table--dynamic table--groups">
          <caption className="visually-hidden">{t("app.groupsTitle")}</caption>
          <colgroup>
            {cols.map((item, index) => {
              if(columns[index] && !columns[index].show) return null
              return (
                <col
                  key={item.id}
                  style={{ width: item.width ? item.width : null }}
                />
              )
            })}
          </colgroup>
          <thead className="table__header">
            <tr>
              {cols.map((item, index) => {
                if(columns[index] && !columns[index].show) return null
                return (
                  <ColumnHead
                    title={item.title}
                    sort={item.sort}
                    key={item.id}
                    order={order}
                    order_by={order_by}
                    th_class="text-small"
                    onOrder={handleChangeOrder}
                  />
                )
              })}
            </tr>
          </thead>
          <tbody>
            {rows.length === 0 ? (
              <tr>
                <td colSpan="12">
                  <span className="text-muted">{t("common.noResults")}</span>
                </td>
              </tr>
            ) : (
              rows.map((item) => {
                const sync_date = parseISO(item.sync_date);
                const creation_date = parseISO(item.creation_date);
                const resync_title = item.isSyncing
                  ? t("actions.syncing")
                  : t("actions.resync");
                const resync_btn_cls = item.isSyncing ? "btn--syncing" : "";
                const resync_icon_cls = item.isSyncing ? "syncing" : "";
                return (
                  <tr
                    key={item.id}
                    className={item.isSyncking ? "gradient" : ""}
                  >
                  {columns[0].show &&
                    <td className="text-small">
                      <GenericTooltip
                        tooltip={resync_title}
                        id={`tooltip_resync_${item.id}`}
                        placement="top"
                      >
                      <a
                        href="#/"
                        role="button"
                        className={`btnlink btnlink--icon btnlink--resync mr-3 ${resync_btn_cls}`}
                        onClick={(evt) => onResync(item.id, evt)}
                        aria-label={resync_title}
                        disabled={item.isLoading}
                      >
                        <span
                          className={`icon icon--refresh icon--small ${resync_icon_cls}`}
                          aria-hidden="true"
                        ></span>
                      </a>
                      </GenericTooltip>
                      <SyncStatus
                        id={item.id}
                        sync_status={item.sync_status}
                        sync_message={item.sync_message}
                      />
                    </td>
                  }
                  {columns[1].show &&
                    <td className="text-small">{item.id}</td>
                  }
                  {columns[2].show &&
                    <td className="text-small">
                      <a
                        href={`#/groups/${item.id}`}
                        onClick={(evt) =>
                          handleClickGroup(item.id, item.group_name, evt)
                        }
                      >
                        {item.group_name}
                      </a>
                    </td>
                  }
                  {columns[3].show &&
                    <td className="text-small">{item.n_pra_campus}</td>
                  }
                  {columns[4].show &&
                    <td className="text-small">{item.n_pdc_campus}</td>
                  }
                  {columns[5].show &&
                    <td className="text-small">{item.n_staff_group}</td>
                  }
                  {columns[6].show &&
                    <td className="text-small">
                      <span
                        className={
                          item.n_students_campus < item.n_students_group
                            ? "color-error"
                            : null
                        }
                      >
                        {item.n_students_campus}
                      </span>
                    </td>
                  }
                  {columns[7].show &&
                    <td className="text-small">
                      <span
                        className={
                          item.n_students_campus > item.n_students_group
                            ? "color-error"
                            : null
                        }
                      >
                        {item.n_students_group}
                      </span>
                    </td>
                  }
                  {columns[8].show &&
                    <td className="text-small">
                      {isValid(creation_date)
                        ? format(creation_date, dateFormat)
                        : "---"}
                    </td>
                  }
                  {columns[9].show &&
                    <td className="text-small">
                      {isValid(sync_date)
                        ? format(sync_date, `${dateFormat} HH:mm`)
                        : "---"}
                    </td>
                  }
                  {columns[10].show &&
                    <td className="text-small">{item.classroom_type_key}</td>
                  }
                  {columns[11].show &&
                    <td className="text-small">
                      <span className="yes-no mr-2">
                        {item.folders_create ? t("common.yes") : t("common.no")}
                      </span>
                      {item.folders_create ? (
                        item.folders_status ? (
                          <IconTooltip
                            tooltip={t("common.foldersOk")}
                            id={`tooltip_folders_${item.id}`}
                            icon="icon--checkmark icon--small color-success"
                            placement="top"
                          />
                        ) : (
                          <IconTooltip
                            tooltip={t("common.foldersError")}
                            id={`tooltip_folders_${item.id}`}
                            icon="icon--alert-full icon--small color-error"
                            placement="top"
                          />
                        )
                      ) : null}
                    </td>
                  }
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default GroupsTable
