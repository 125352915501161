
import { useEffect, useState, useCallback } from "react"
import { useLocation } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useHeaderState } from 'context/HeaderContext'
import BrandHeader from "components/BrandHeader"
import Breadcrumb, { BreadcrumbItem } from "components/Breadcrumb"
import Title from "components/Title"
import ViewTransition from "components/ViewTransition"
import GenericTooltip from "components/GenericTooltip"
import Spinner from "components/Spinner"
import { checkEngine, checkQueues } from "services/checkStatus"
import { useSessionContext } from "context/SessionContext"



const CheckStatus = ({ status, lbl='Estat', short='E', lblCheck = 'Comprovar', lblChecking='Comprovant', className='', onCheck = f => f}) => {
  const {t} = useTranslation()
  switch (status) {
    case 'ok':
      return (
        <GenericTooltip
          tooltip={lblCheck}
          id='tooltip-engine-status'
          placement="top"
        >
        <a href="/#" role="button" className={`btnlink btnlink--regular ${className}`} onClick={onCheck}>
          <span
            className="icon icon--checkmark icon--small icon--before color-success"
            aria-hidden="true"
          ></span>
          <span className="hidden-xs">{lbl}</span><span className="visible-inline-xs hidden-sm hidden-md hidden-lg hidden-xl">{short}</span><span className="icon-alt"> {t("common.ok")}</span>
        </a>
        </GenericTooltip>
      )
    case 'error':
      return (
        <GenericTooltip
          tooltip={lblCheck}
          id='tooltip-engine-status'
          placement="top"
        >
        <a href="/#" role="button" className={`btnlink btnlink--regular ${className}`} onClick={onCheck}>
          <span
            className="icon icon--alert-full icon--small icon--before color-error"
            aria-hidden="true"
          ></span>
          <span className="hidden-xs">{lbl}</span><span className="visible-inline-xs hidden-sm hidden-md hidden-lg hidden-xl">{short}</span><span className="icon-alt"> {t("common.error")}</span>
        </a>
        </GenericTooltip>
      )
    default:
      return (
        <GenericTooltip
          tooltip={lblChecking}
          id='tooltip-engine-status'
          placement="top"
        >
        <a href="/#" role="button" className={`btnlink btnlink--regular ${className}`} onClick={onCheck} disabled>
          <Spinner label={t("app.processing")} type="icon" />
          <span className="hidden-xs">{lbl}</span><span className="visible-inline-xs hidden-sm hidden-md hidden-lg hidden-xl">{short}</span>
        </a>
        </GenericTooltip>
      )
  }
}


const Header = () => {
  const { pathname } = useLocation()
  const {t} = useTranslation();
  const {title, breadcrumb, config} = useHeaderState()
  const { env } = useSessionContext()

  const appTitle = t('app.title')

  const [engine, setEngine] = useState(null)
  const [queues, setQueues] = useState(null)

  const handleCheckEngine = useCallback((reset) => {
    if(reset) setEngine(null)
    checkEngine(env)
      .then((response) => {
        if (response.ok) setEngine('ok')
        else setEngine('error')
      })
      .catch(() => {
        setEngine('error')
      })
  },[env])

  const handleCheckQueues = useCallback((reset) => {
    if(reset) setQueues(null)
    checkQueues(env)
      .then((response) => {
        if (response.ok) setQueues('ok')
        else setQueues('error')
      })
      .catch(() => {
        setQueues('error')
      })
  },[env])

  useEffect(() => {
    handleCheckEngine()
    handleCheckQueues()
  }, [pathname, handleCheckEngine, handleCheckQueues])

  return (
    <header id="header" role="banner" className="heading fixed-heading">
      <Helmet title={title === appTitle ? title : `${title} · ${appTitle}`} />
      <div className="container">
        <BrandHeader />
        <div className="d-flex justify-content-between align-items-center">
          <Breadcrumb>
            {breadcrumb.map((item, index) => (
              <BreadcrumbItem to={item.to} active={ index === breadcrumb.length - 1 } key={item.to}>{item.title}</BreadcrumbItem>
            ))}
          </Breadcrumb>
          <nav className="nav-menu flex-shrink-0" aria-label="Menu">
            <CheckStatus
              status={queues}
              short={t("app.queuesShort")}
              lbl={t("app.queuesStatus")}
              lblCheck={t("app.checkQueuesStatus")}
              lblCkecing={t("app.checkingQueuesStatus")}
              onCheck={(e) => {e.preventDefault(); handleCheckQueues(true)}} />
            <CheckStatus
              className="ml-3"
              status={engine}
              short={t("app.engineShort")}
              lbl={t("app.engineStatus")}
              lblCheck={t("app.checkEngineStatus")}
              lblCkecing={t("app.checkingEngineStatus")}
              onCheck={(e) => {e.preventDefault(); handleCheckEngine(true)}} />
          { config ?
            <Link to="/config" className="btnlink btnlink--regular ml-3" aria-label={t('app.configTitle')}>
              <span className="icon icon--settings icon--small icon--before"></span>
              <span className="hidden-xs">{t('app.configTitle')}</span>
            </Link>
           : null }
          </nav>
        </div>
      </div>
      <div className="container">
        <ViewTransition>
          <Title title={title} />
        </ViewTransition>
      </div>
    </header>
  )
}

export default Header
