import queryString from "query-string"
import { getHeaders, apiCall } from "services/api"

export const getHistoric = ({ lang, env, page, order, order_by, size, id }) => {
  const headers = getHeaders(lang);
  const params = {
    size: size,
    page: page,
    lang: lang,
    sort: order_by + ',' + order
  }

  const url = process.env.REACT_APP_DEMO_MODE === '1' ? 
    `${env.consumer_api_base}/${process.env.REACT_APP_API_HISTORIC}` : 
    `${env.consumer_api_base}/${process.env.REACT_APP_API_GROUPS}/${id}/${process.env.REACT_APP_API_HISTORIC}?${queryString.stringify(params)}`

  const request = new Request(
    url,
    {
      method: "GET",
      credentials: "include",
      headers: headers,
    }
  )
  return apiCall(request)
}
