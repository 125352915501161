
import { useReducer } from 'react'
import * as c from 'config/constants'

const initialState = {
  content: [],
  isDownload: false,
  isLoading: true,
  isError: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case c.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isDownload: false,
        isError: false
      }
    case c.FETCH_SUCCESS:
      return {
        ...state,
        content: [...action.payload],
        isLoading: false,
        isDownload: true
        
      }
    case c.FETCH_ERROR:
      return {
        ...state,
        isError: true,
        isDownload: false,
        isLoading: false
      }
    default:
      return state
  }
}

export function useExport() {
  const [state, dispatch] = useReducer(reducer, initialState)
  return { exportState: state, exportDispatch: dispatch }
}