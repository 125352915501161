
export const DEV = {
    consumer_api_base:  "http://localhost:8080",
    queues_api_base:    "http://localhost:3000",
    oauth: {
        callback_url: 'http://localhost:3000/callback',
        client_id: 'gchallenge',
        response_type: 'code',
        scope: 'openid gchallenge.rw',
        redirect_url: 'http://localhost:3000',
        oauth_server: 'https://auth.test.uoc.edu',
        entitlement_role: 'gchallenge'
    }
}

export const TEST = {
    consumer_api_base:  "https://admincv-consumer-admincv-ws-test.cloudapps.uoc.es",
    queues_api_base:    "https://admincv-ws-admincv-ws-test.cloudapps.uoc.es",
    oauth: {
        callback_url: 'https://gadmin-test.uoc.edu/callback',
        client_id: 'gchallenge',
        response_type: 'code',
        scope: 'openid gchallenge.rw',
        redirect_url: 'https://gadmin-test.uoc.edu/#/',
        oauth_server: 'https://auth.test.uoc.edu',
        entitlement_role: 'gchallenge',
        logout: {
            url: 'https://id-provider.test.uoc.edu/idp/profile/Logout',
            redirect_url: 'https://uoc.edu',
            timeout: 5000
        }
    }
}

export const PRE = {
    consumer_api_base:  "https://admincv-consumer-admincv-ws-pre.cloudapps.uoc.es",
    queues_api_base:    "https://admincv-ws-admincv-ws-pre.cloudapps.uoc.es",
    oauth: {
        callback_url: 'https://gadmin-pre.uoc.edu/callback',
        client_id: 'gchallenge',
        response_type: 'code',
        scope: 'openid gchallenge.rw',
        redirect_url: 'https://gadmin-pre.uoc.edu/#/',
        oauth_server: 'https://auth.pre.uoc.edu',
        entitlement_role: 'gchallenge',
        logout: {
            url: 'https://id-provider.pre.uoc.edu/idp/profile/Logout',
            redirect_url: 'https://uoc.edu',
            timeout: 5000
        }
    }
}

export const PRO = {
    consumer_api_base:  "https://admincv-consumer-admincv-ws.cloudapps.uoc.es",
    queues_api_base:    "https://admincv-ws-admincv-ws.cloudapps.uoc.es",
    oauth: {
        callback_url: 'https://gadmin.uoc.edu/callback',
        client_id: 'gchallenge',
        response_type: 'code',
        scope: 'openid gchallenge.rw',
        redirect_url: 'https://gadmin.uoc.edu/#/',
        oauth_server: 'https://auth.uoc.edu',
        entitlement_role: 'gchallenge',
        logout: {
            url: 'https://id-provider.uoc.edu/idp/profile/Logout',
            redirect_url: 'https://uoc.edu',
            timeout: 5000
        }
    }
}

export const DEMO = {
    consumer_api_base:  "http://uoc.tambaqui.es/admincv_consumer/api",
    queues_api_base:    "http://uoc.tambaqui.es/admincv_consumer/api"
}
