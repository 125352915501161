import ViewTransition from "components/ViewTransition"
import ErrorBoundary from "components/ErrorBoundary"

const MainContainer = ({ children }) => (
    <ErrorBoundary>
        <ViewTransition>
            <main id="main" className="main with-sticky-header" role="main">
                <div className="container">
                    {children}
                </div>
            </main>
        </ViewTransition>
    </ErrorBoundary> 
)

export default MainContainer