import { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { Alert } from "react-bootstrap"

import { useHeaderDispatch } from 'context/HeaderContext'

import ViewTransition from "components/ViewTransition"

const NotFound = () => {
    const {t} = useTranslation();

    const setHeader = useHeaderDispatch()

    useEffect(function () {
        setHeader({
            title: t('app.notFoundTitle'),
            breadcrumb: [
                { to: '/', title: t('app.home')},
                { to: '/404', title: t('app.notFoundTitle')}
            ],
            config: true
        })
    }, [setHeader, t])

    return (
      <ViewTransition>
        <Alert variant="-warning">
          <p>{t("common.notFound")}</p>
        </Alert>
      </ViewTransition>
    )
}

export default NotFound
