
import { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
import { format, isValid, parseISO } from "date-fns"
import classNames from "classnames"
import * as c from 'config/constants'

import ViewTransition from "components/ViewTransition"
import Loading from 'components/Loading'

import { useSessionContext } from 'context/SessionContext'
import { useAppState, useAppDispatch } from 'context/AppContext'
import { useHeaderDispatch } from 'context/HeaderContext'
import { useGroup } from 'hooks/useGroup'
import { syncGroup } from 'services/syncGroup'


const SyncStatus = ({ sync_status, sync_message, isLoading = false }) => {
  const {t} = useTranslation()
  if (isLoading === true) {
    return (
      <span className="sync-status">
        <span
          className="icon icon--clock-full icon--small icon--before color-primary"
          aria-hidden="true"
        ></span>
        {t("common.syncProcess")}
      </span>
    )
  }
  switch (sync_status) {
    case 2:
      return (
        <span className="sync-status">
          <span
            className="icon icon--checkmark icon--small icon--before color-success"
            aria-hidden="true"
          ></span>
          {t("common.syncOk")}
        </span>
      )
    case 1:
      return (
        <span className="sync-status">
          <span
            className="icon icon--clock-full icon--small icon--before color-primary"
            aria-hidden="true"
          ></span>
          {t("common.syncProcess")}
        </span>
      )
    case 0:
      return (
        <span className="sync-status">
          <span
            className="icon icon--alert-full icon--small icon--before color-error"
            aria-hidden="true"
          ></span>
          {t("common.syncError")}
        </span>
      )
    default:
      return (
        <span className="sync-status">
          <span
            className="icon icon--alert-full icon--small icon--before color-error"
            aria-hidden="true"
          ></span>
          {t("common.syncError")}
        </span>
      )

  }
}


const GroupItemsList = ({list, empty, type = "strings", notFound = ''}) => (
    <ul className="list--unstyled pb-2">
        {list.length ?
            list.map((item, index) => {
                const cls = classNames("mb-1", {
                    'color-error': type === 'objects' && !item.found,
                })
                return (
                    <li className={cls} key={type === "strings" ? `${item}_${index}`: `${item.label}_${index}`}>
                        {type === "strings" ? item : `${item.label}`}
                        {type === 'objects' && !item.found ? <span className="visually-hidden">{notFound}</span> : null }
                    </li>)
            }) :
            <li className="mb-1 text-muted">{empty}</li> }
    </ul>
)

const GroupMenu = ({ syncing = false, onClickHistory = f => f, onClickFolders = f => f , onClickResync = f => f}) => {
    const {t} = useTranslation();
    const sync_btn_cls = classNames("btn btn--primary btn--large btn--resync", {
        'btn--syncing': syncing,
    })
    const sync_icon_cls = classNames("icon icon--refresh icon--small icon--before", {
        'syncing': syncing,
    })
    return (
        <nav aria-label={t('common.groupMenu')}>
            <ul className="list--unstyled list--inline clearfix list--nav ruler ruler--thin py-3 mb-4">
                <li>
                    <button type="button" className="btn btn--secondary btn--large btn--save" onClick={onClickHistory}>
                        <span className="icon icon--list-full icon--small icon--before" aria-hidden="true"></span>
                        <span className="btn-label">{t('actions.syncHistoric')}</span>
                    </button>
                </li>
                <li>
                    <button type="button" className="btn btn--secondary btn--large" onClick={onClickFolders}>
                        <span className="icon icon--folder-full icon--small icon--before" aria-hidden="true"></span>
                        <span className="btn-label">{t('actions.groupFolders')}</span>
                    </button>
                </li>
                <li className="float-right">
                    <button type="button" className={sync_btn_cls} onClick={onClickResync} disabled={syncing}>
                        <span className={sync_icon_cls} aria-hidden="true"></span>
                        <span className="btn-label">{syncing ? t('actions.syncing') : t('actions.resync') }</span>
                    </button>
                </li>
            </ul>
        </nav>
    )
}

const Group = () => {
    const [loading, setLoading] = useState(false)
    const {t} = useTranslation()
    const { id } = useParams()
    const { lang, env, dateFormat } = useSessionContext()
    const setHeader = useHeaderDispatch()
    const { group_name } = useAppState()
    const { groupState, groupDispatch } = useGroup(id)
    const appDispatch = useAppDispatch()
    const history = useHistory()

    useEffect(function () {
        const gname = group_name ? group_name : groupState.group.group_name ? groupState.group.group_name : null
        setHeader({
            title: gname ? t('app.groupTitle', {group: gname}) : t('actions.loading'),
            breadcrumb: [
                { to: '/', title: t('app.groupsTitle')},
                { to: `/groups/${id}`, title: gname ? gname : t('actions.loading') }
            ],
            config: true
        })
    }, [setHeader, t, group_name, groupState, id])

    const handleClickHistory = () => {
      if (groupState.group.group_name) {
        appDispatch({
          type: c.SET_GROUP,
          payload: { id, group_name: groupState.group.group_name },
        });
      }
      history.push(`/groups/${id}/historic`);
    }

    const handleClickFolders = () => {
      if (groupState.group.group_name) {
        appDispatch({
          type: c.SET_GROUP,
          payload: { id, group_name: groupState.group.group_name },
        });
      }
      history.push(`/groups/${id}/folders`);
    }

    const handleResync = () => {
      setLoading(true)
      groupDispatch({ type: c.SYNC_GROUP_REQUEST });
      syncGroup({ lang, env, id })
        .then((data) => {
          setLoading(false)
          if (data.type && data.type === "error") {
            groupDispatch({
              type: c.SYNC_GROUP_ERROR,
              payload: { error: data.message },
            })
            appDispatch({ type: c.SET_MESSAGES, payload: [data] })
          } else {
            groupDispatch({
              type: c.SYNC_GROUP_SUCCESS,
              payload: {
                sync_status: data.sync_status,
                sync_date: data.sync_date,
                sync_message: data.sync_message,
              },
            })
            window.location.reload(false);
          }
        })
        .catch((error) => {
          setLoading(false)
          groupDispatch({
            type: c.SYNC_GROUP_FAILURE,
            payload: { error: error.message },
          })
          appDispatch({ type: c.SET_MESSAGES, payload: [error] })
        })
    }


    if (groupState.isLoading ) return <Loading />

    const creation_date = parseISO(groupState.group.creation_date)
    const sync_date = parseISO(groupState.group.sync_date)

    const students_campus = groupState.group.students_campus.map((item) => {
        const found = groupState.group.students_group.find(k => k === item)
        return { label: item, found: found !== undefined }
    })

    const students_group = groupState.group.students_group.map((item) => {
        const found = groupState.group.students_campus.find(k => k === item)
        return { label: item, found: found !== undefined }
    })

    return (
      <ViewTransition>
            <GroupMenu
              syncing={groupState.isSyncing}
              onClickHistory={handleClickHistory}
              onClickFolders={handleClickFolders}
              onClickResync={handleResync}
            />

            <div className="row">
              <div className="col-lg-4">
                <div className="mr-lg-2 pr-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("detall.domainCode")}
                  </h2>
                  <div className="pb-2">{groupState.group.id}</div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mx-lg-2 px-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("columns.aulaType")}
                  </h2>
                  <div className="pb-2">
                    {groupState.group.classroom_type_key}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ml-lg-2 pl-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("detall.groupName")}
                  </h2>
                  <div className="pb-2">{groupState.group.group_name}</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="mr-lg-2 pr-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("columns.createDate")}
                  </h2>
                  <div className="pb-2">
                    {isValid(creation_date)
                      ? format(creation_date, dateFormat)
                      : "---"}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mx-lg-2 px-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("columns.syncDate")}
                  </h2>
                  <div className="pb-2">
                    {isValid(sync_date)
                      ? format(sync_date, `${dateFormat} HH:mm`)
                      : "---"}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="ml-lg-2 pl-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("columns.syncStatus")}
                  </h2>
                  <div className="pb-2">
                    <SyncStatus sync_status={groupState.group.sync_status} sync_message={groupState.group.sync_message} isLoading={loading}/>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="mr-lg-2 pr-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("detall.praCampus")}
                  </h2>
                  <GroupItemsList
                    list={groupState.group.pra_campus}
                    empty={t("common.noResults")}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mx-lg-2 px-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("detall.pdcCampus")}
                  </h2>
                  <GroupItemsList
                    list={groupState.group.pdc_campus}
                    empty={t("common.noResults")}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="mx-lg-2 px-lg-1 mb-4">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("detall.staffGroup")}
                  </h2>
                  <GroupItemsList
                    list={groupState.group.staff_group}
                    empty={t("common.noResults")}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="mr-lg-2 pr-lg-1 mb-5">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("detall.studentsCampus")}
                  </h2>
                  <GroupItemsList
                    list={students_campus}
                    empty={t("common.noResults")}
                    type="objects"
                    notFound="El estudiante no está en el grupo"
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mx-lg-2 px-lg-1 mb-5">
                  <h2 className="h5 ruler ruler--primary ruler--thin p-y2 mb-1">
                    {t("detall.studentsGroup")}
                  </h2>
                  <GroupItemsList
                    list={students_group}
                    empty={t("common.noResults")}
                    type="objects"
                    notFound="El estudiante no está en campus"
                  />
                </div>
              </div>
            </div>

            <GroupMenu
              syncing={groupState.isSyncing}
              onClickHistory={handleClickHistory}
              onClickFolders={handleClickFolders}
              onClickResync={handleResync} />
      </ViewTransition>
    )
}

export default Group;
