import { OverlayTrigger, Tooltip } from "react-bootstrap"

const IconTooltip = ({id, tooltip, className, btnClassName, icon = 'icon--info-full', placement= 'right'}) => {
  const popperConfig = {
    modifiers: [
      { 
        name: 'offset',
        options: {
          offset: [3, 3],
          enabled: true
        }
      }
    ]
  }
  return (
    <OverlayTrigger
      popperConfig={popperConfig}
      overlay={
        <Tooltip id={id} className={className}>
            {tooltip}
        </Tooltip>
      }
      
      placement={placement}
    >
      <button className={`btn--unstyled btn--tooltip d-inline-block ${btnClassName ? btnClassName : ''}`} aria-label="Info">
        <span className={`icon ${icon}`} aria-hidden="true" />
      </button>
    </OverlayTrigger>
  )
}

export default IconTooltip