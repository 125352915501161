import { useContext, useReducer, createContext } from 'react'
import * as c from 'config/constants'

const GroupsStateContext = createContext()
const GroupsDispatchContext = createContext()


export const initialState = {
  size: c.PER_PAGE_DEFAULT,
  total: 0,
  page: 0,
  order: c.GROUPS_DEFAULT_ORDER,
  order_by: c.GROUPS_DEFAULT_ORDER_BY,
  code: '',
  sync_status: '',
  group_name: '',
  semester: '',
  classroom_type: '',
  start_date_classroom: null,
  end_date_classroom: null,
  start_date_group: null,
  end_date_group: null,
  isLoading: false,
  userColumns: false,
  columns: [
    { id: 1, show: true, priority: 'xs' },   // sync
    { id: 2, show: true, priority: 'sm' },   // code
    { id: 3, show: true, priority: 'xs' },   // group
    { id: 4, show: true, priority: 'xl' },   // n pra
    { id: 5, show: true, priority: 'xl' },   // n pdc
    { id: 6, show: true, priority: 'xl' },   // n staff
    { id: 7, show: true, priority: 'md' },   // n students campus
    { id: 8, show: true, priority: 'md' },   // n students group
    { id: 9, show: true, priority: 'sm' },   // creation date
    { id: 10, show: true, priority: 'sm' },  // sync date
    { id: 11, show: true, priority: 'xs' },  // classroom type
    { id: 12, show: true, priority: 'xs' }   // folders
  ]
}



function reducer(state, action) {
  switch (action.type) {
    case c.SET_TOTAL:
      return {
        ...state,
        total: action.payload,
      }
    case c.TOGGLE_COLUMN:
      return {
        ...state,
        userColumns: true,
        columns: state.columns.map(item => {
          return item.id + "" !== action.payload.id + ""
          ? item
          : { ...item, show: action.payload.show};
        })
      }
    case c.SET_COLUMNS:
      return {
        ...state,
        columns: [...action.payload],
      }
    case c.SET_LIMIT:
      return {
        ...state,
        page: 0,
        size: action.payload,
      }
    case c.SET_ORDER:
      return {
        ...state,
        page: 0,
        order_by: action.payload.order_by,
        order: action.payload.order
      }
    case c.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      }
    case c.SET_SYNC:
      return {
        ...state,
        sync_status: action.payload,
      }
    case c.SET_SEARCH:
      return {
        ...state,
        page: 0,
        group_name: action.payload.group_name,
        code: action.payload.code,
        sync_status: action.payload.sync_status,
        semester: action.payload.semester,
        classroom_type: action.payload.classroom_type,
        start_date_group: action.payload.start_date_group,
        end_date_group: action.payload.end_date_group,
        start_date_classroom: action.payload.start_date_classroom,
        end_date_classroom: action.payload.end_date_classroom
      }
    case c.SET_CODE:
      return {
        ...state,
        page: 0,
        code: action.payload,
      }
    case c.SET_GROUP:
      return {
        ...state,
        page: 0,
        group_name: action.payload,
      }
    case c.SET_SEMESTER:
      return {
        ...state,
        page: 0,
        semester: action.payload,
      }
    case c.SET_CLASSROOM_TYPE:
      return {
        ...state,
        page: 0,
        classroom_type: action.payload,
      }
    case c.SET_START_DATE_CLASSROOM:
      return {
        ...state,
        page: 0,
        start_date_classroom: action.payload,
      }
    case c.SET_END_DATE_CLASSROOM:
      return {
        ...state,
        page: 0,
        end_date_classroom: action.payload,
      }
    case c.SET_START_DATE_GROUP:
      return {
        ...state,
        page: 0,
        start_date_group: action.payload,
      };
    case c.SET_END_DATE_GROUP:
      return {
        ...state,
        page: 0,
        end_date_group: action.payload,
      }
    case c.SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      }
    case c.RESET_FILTERS:
      return {
        ...state,
        page: 0,
        code: "",
        sync_status: "",
        group_name: "",
        semester: "",
        classroom_type: "",
        start_date_classroom: null,
        end_date_classroom: null,
        start_date_group: null,
        end_date_group: null,
      }
    default:
      return state;
  }
}

function GroupsProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <GroupsStateContext.Provider value={state}>
      <GroupsDispatchContext.Provider value={dispatch}>
        {children}
      </GroupsDispatchContext.Provider>
    </GroupsStateContext.Provider>
  )
}

function useGroupsFiltersState() {
  const context = useContext(GroupsStateContext)
  if (context === undefined) {
    throw new Error('useGroupsFiltersState must be used within a GroupProvider')
  }
  return context
}

function useGroupsFiltersDispatch() {
  const context = useContext(GroupsDispatchContext)
  if (context === undefined) {
    throw new Error('useGroupsFiltersDispatch must be used within a GroupProvider')
  }
  return context
}


export {GroupsProvider, useGroupsFiltersState, useGroupsFiltersDispatch}
