
import { forwardRef } from "react"
import { Dropdown } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { format, isValid, parseISO } from "date-fns"
import ReactExport from "react-export-excel"

import * as c from 'config/constants'

import Limits from "components/Limits"
import ConditionalWrapper from "components/ConditionalWrapper"
import GenericTooltip from "components/GenericTooltip"

import { useSessionContext } from 'context/SessionContext'
import { useExport } from "hooks/useExport"
import { useGroupsFiltersState, useGroupsFiltersDispatch, initialState as ist } from 'context/GroupsContext'
import { getGroups } from 'services/getGroups'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn


const ExportDownload = ({data = [], dateFormat}) => {
  const {t} = useTranslation()
  console.log('datos', data)
  return(
    <ExcelFile
      hideElement={true}
      filename={`${t("app.groups")}_${format(new Date(), "ddMMyyyyHHMM")}`}
    >
      <ExcelSheet data={data} name={t("app.groups")}>
        <ExcelColumn label={t("columns.domainCode")} value="id" />
        <ExcelColumn label={t("columns.groupName")} value="group_name" />
        <ExcelColumn label={t("filters.semester")} value="semester" />
        <ExcelColumn
          label={t("columns.praCampus")}
          value="n_pra_campus"
              />
        <ExcelColumn
          label={t("columns.pdcCampus")}
          value="n_pdc_campus"
        />
        <ExcelColumn
          label={t("columns.staffGroup")}
          value="n_staff_group"
        />
        <ExcelColumn
          label={t("columns.studentsCampus")}
          value="n_students_campus"
        />
        <ExcelColumn
          label={t("columns.studentsGroup")}
          value="n_students_group"
        />
        <ExcelColumn
          label={t("columns.createDate")}
          value={(col) =>
            isValid(parseISO(col.creation_date))
              ? format(parseISO(col.creation_date), `${dateFormat}`)
              : "---"
          }
        />
        <ExcelColumn
          label={t("columns.syncDate")}
          value={(col) =>
            isValid(parseISO(col.sync_date))
              ? format(parseISO(col.sync_date), `${dateFormat} HH:mm`)
              : "---"
          }
        />
        <ExcelColumn
          label={t("columns.syncStatus")}
          value={(col) =>
            col.sync_status === 2
              ? t("common.ok")
              : col.sync_status === 1
              ? t("common.processing")
              : t("common.error")
          }
        />
        <ExcelColumn
          label={t("columns.aulaType")}
          value="classroom_type_key"
        />
        <ExcelColumn
          label={t("columns.aulaFolders")}
          value={(col) =>
            col.folders_create ? t("common.yes") : t("common.no")
          }
        />
        <ExcelColumn
          label={t("columns.foldersStatus")}
          value={(col) =>
            col.folders_create
              ? col.folders_status
              ? t("common.ok")
              : t("common.error")
              : "---"
          }
        />
      </ExcelSheet>
    </ExcelFile>
  )
}

const ColumnsToggle = forwardRef(({ id, children, className, 'aria-haspopup': hasPopup, 'aria-expanded': expanded, onClick }, ref) => {
  return (
    <a
      href="#/"
      role="button"
      id={id}
      className={className}
      aria-haspopup={hasPopup}
      aria-expanded={expanded}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </a>
  );
})


const ColumnsMenu = forwardRef (({ children, style, className, 'aria-labelledby': labelledBy, 'x-placement': placement }, ref) => {
  const {t} = useTranslation()
  return (
    <div
      data-placement={placement}
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labelledBy}
      role="menu"
    >
      <div className="dropdown-triangle"></div>
      <fieldset>
        <legend className="visually-hidden">{t('filters.selectColumns')}</legend>
        <ul className="list--unstyled list--dropdown">
          {children}
        </ul>
      </fieldset>
    </div>

    )
  }
)

const GroupsMenu = ({ cols = [], allCols = true }) => {
    const {t} = useTranslation()
    const gfDispatch = useGroupsFiltersDispatch()
    const { exportState, exportDispatch } = useExport();
    const { lang, env, dateFormat } = useSessionContext();

    const {
      size,
      order,
      order_by,
      total,
      sync_status,
      code,
      group_name,
      semester,
      classroom_type,
      start_date_classroom,
      end_date_classroom,
      start_date_group,
      end_date_group,
      columns,
    } = useGroupsFiltersState()

    const reset = (sync_status !== ist.sync_status) ||
                  (code !== ist.code) ||
                  (group_name !== ist.group_name) ||
                  (semester !== ist.semester) ||
                  (classroom_type !== ist.classroom_type) ||
                  (start_date_classroom !== ist.start_date_classroom) ||
                  (end_date_classroom !== ist.end_date_classroom) ||
                  (start_date_group !== ist.end_date_group) ||
                  (end_date_group !== ist.end_date_group)

    const onChangeLimit = (limit) => {
        gfDispatch ({ type: c.SET_LIMIT, payload: limit })
    }

    const onReset = () => {
        gfDispatch ({ type: c.RESET_FILTERS })
    }

    const onExport = () => {
        exportDispatch({ type: c.FETCH_REQUEST });
        getGroups({
          lang,
          env,
          order,
          order_by,
          sync_status,
          code,
          group_name,
          semester,
          classroom_type,
          start_date_group,
          end_date_group,
          start_date_classroom,
          end_date_classroom,
        })
        .then((res) => {
            exportDispatch({ type: c.FETCH_SUCCESS, payload: res.content });
        })
        .catch((error) => {
            exportDispatch({ type: c.FETCH_ERROR, payload: error });
        })
    }

    const onToggleColumn = (evt, id) => {
        gfDispatch ({ type: c.TOGGLE_COLUMN, payload: {id: id, show: evt.target.checked } })
    }

    const popperConfig = {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [1, 8],
            enabled: true
          }
        }
      ]
    }

    const colsToggleCls = allCols ? '' : 'is-on'

    return (
      <nav
        className="nav-options nav-options--groups d-sm-flex align-items-end mb-2"
        aria-label={t("common.listOptions")}
      >
        <div className="mr-auto mb-3 mb-sm-0">
          <span className="result-total">
            <strong>{total}</strong> {t("common.results")}
          </span>
          <Limits limit={size} onChange={onChangeLimit} />
        </div>
        <ul className="list--unstyled list--inline list--options mb-3 mb-sm-0 float-right">
          {reset && (
            <li>
              <a href="#/" role="button" className="btnlink btnlink--regular" onClick={(evt) => {evt.preventDefault(); onReset()}}>
                {t("filters.clearFilters")}
                <span
                  className="icon icon--close icon--xsmall icon--after"
                  aria-hidden="true"
                ></span>
              </a>
            </li>
          )}
          <li>
            <ConditionalWrapper
              condition={total > c.EXPORT_LIMIT}
              wrapper={(children) => (
                <GenericTooltip
                  tooltip={t('common.exportLimitAlert', {limit: c.EXPORT_LIMIT})}
                  id={`tooltip_export`}
                  placement="top"
                >
                  <span className="is-disabled">{children}</span>
                </GenericTooltip>
              )}
            >

              <a href="#/"
                role="button"
                className="btnlink btnlink--regular"
                onClick={(evt) => {evt.preventDefault(); (total <= c.EXPORT_LIMIT) && onExport() }}
                aria-disabled={total > c.EXPORT_LIMIT}
              >
                {t("actions.export")}
                <span
                  className="icon icon--excel icon--xsmall icon--after"
                  aria-hidden="true"
                ></span>
              </a>
            </ConditionalWrapper>
          </li>
          <li>
            <Dropdown>

              <Dropdown.Toggle as={ColumnsToggle} id="dropdown-columns" className="btnlink btnlink--regular dropdown-toggle">
                {t("filters.showColumns")}
                <span className={`icon icon--columns icon--xsmall icon--after ${colsToggleCls}`} aria-hidden="true">
                  <span className="lines"></span>
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu as={ColumnsMenu} align="right" popperConfig={popperConfig} aria-labelledby="dropdown-columns" className="dropdown-menu-columns">
                {cols.map((item, index) => (
                    <li key={item.id}>
                      <div className="form-check">
                          <label htmlFor={`col-${item.id}`}>
                              <input type="checkbox" id={`col-${item.id}`} checked={columns[index].show} onChange={(evt) => onToggleColumn(evt, item.id)} />
                              <span className="icon icon--checkbox-off icon--small" aria-hidden="true"></span>
                              {item.title}
                          </label>
                      </div>
                    </li>
                ))}
              </Dropdown.Menu>

            </Dropdown>
          </li>
        </ul>
        {exportState.isDownload && <ExportDownload data={exportState.content} dateFormat={dateFormat} />}
      </nav>
    )
}

export default GroupsMenu
