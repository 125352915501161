
/* api utils */

const getStatus = (response) => {
  if (!response.ok) {
    return response.json().then((error) => {
      if (error.message) {
        return Promise.reject(new Error(error.message))
      } else {
        return Promise.reject(new Error(response.statusText))
      }
    })
  }
  return response;
}


const getJson = (response) => {
  return response.json();
}

const getData = (response) => {
  if (response.error) {
    return new Error(response.error.message)
  /*}else if (response.type && response.type === 'error') {
    return new Error(response.message);*/
  }
  else {
    return response;
  }
}

export const getHeaders = (lang) => {
    return {
      Accept: "application/json",
      "Accept-Language": lang || "ca",
    }
}

export const apiCall = (request) => {
  return fetch(request)
    .then(getStatus)
    .then(getJson)
    .then(getData)
    .catch((error) => {
      throw error;
    })
}
