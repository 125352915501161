import { Modal } from "react-bootstrap"

const ConfirmDialog = ({
  show,
  title,
  body,
  confirm,
  cancel,
  onConfirm = f => f,
  onCancel = f => f
}) => {
  return (
    <Modal
      size="sm"
      show={show}
      className="modal-confirm"
      aria-labelledby="modal-confirm-title"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <h3 className="mb-2 modal-title h4" id="modal-confirm-title">
          {title}
        </h3>
      </Modal.Header>
      { body && <Modal.Body>{body}</Modal.Body>}
      <Modal.Footer>
        <button
          type="button"
          className="btn btn--primary btn--large"
          onClick={onConfirm}
        >
          {confirm}
        </button>
        <button
          type="button"
          className="btn btn--secondary btn--large"
          onClick={onCancel}
        >
          {cancel}
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmDialog
