
import { useEffect, useReducer} from 'react'
import * as c from 'config/constants'

import { getHistoric } from 'services/getHistoric'
import { useSessionContext } from 'context/SessionContext'
import { useAppDispatch } from 'context/AppContext'

const initialState = {
  size: c.PER_PAGE_DEFAULT,
  order: c.HISTORIC_DEFAULT_ORDER,
  order_by: c.HISTORIC_DEFAULT_ORDER_BY,
  total: 0,
  page: 0,
  historic: [],
  firstLoad: true,
  isLoading: true,
  isError: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case c.SET_TOTAL:
      return {
        ...state,
        total: action.payload
      }
    case c.SET_LIMIT:
      return {
        ...state,
        page: 0,
        size: action.payload
      }
    case c.SET_ORDER:
      return {
        ...state,
        page: 0,
        order_by: action.payload.order_by,
        order: action.payload.order
      }
    case c.SET_PAGE:
      return {
        ...state,
        page: action.payload
      }
    case c.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case c.FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        firstLoad: false
      }
    case c.FETCH_ERROR:
      return {
        ...state,
        isError: true,
        firstLoad: false,
        isLoading: false
      }
    case c.EXPAND_COLLAPSE:
      return {
          ...state,
          historic: state.historic.map(item => {
            return item.id + "" !== action.payload + ""
            ? item
            : { ...item, isExpanded: !item.isExpanded}
          })
      }
    default:
      return state
  }
}

export function useHistoric(id) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { lang, env } = useSessionContext()
  const appDispatch = useAppDispatch()

  const { page, size, order, order_by } = state

  useEffect(
    function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      dispatch({ type: c.FETCH_REQUEST });
      getHistoric({
        lang,
        env,
        page,
        size,
        order,
        order_by,
        id
      })
        .then((res) => {
          if (res.type && res.type === "error") {
            dispatch({ type: c.FETCH_ERROR, payload: res });
            appDispatch({ type: c.SET_MESSAGES, payload: [res] })
          } else {
            const historic = res.content.map(obj=> ({ ...obj, isExpanded: false }))
            dispatch({
              type: c.FETCH_SUCCESS,
              payload: {
                historic,
                total: res.totalElements
              },
            })
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: c.FETCH_ERROR, payload: error })
          appDispatch({ type: c.SET_MESSAGES, payload: [error] })
        });
    },
    [lang, env, page, size, order, order_by, id, appDispatch]
  )
  
  return { historicState: state, historicDispatch: dispatch }
}