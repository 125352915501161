import { useRef } from 'react'
import { CSSTransition } from "react-transition-group"


const ViewTransition = (props) => {
    const nodeRef = useRef(null)
    return ( 
        <CSSTransition
            appear={true}
            in={true}
            timeout={300}
            classNames="appear"
            nodeRef={nodeRef}
        >
            <div ref={nodeRef}>
                {props.children}
            </div>
        </CSSTransition>
    )
}

export default ViewTransition