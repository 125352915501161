import { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import * as c from 'config/constants'

import ViewTransition from "components/ViewTransition"
import GroupsFilters from "components/GroupsFilters"
import GroupsMenu from "components/GroupsMenu"
import GroupsTable from "components/GroupsTable"
import Pagination from "components/Pagination"
import Loading from 'components/Loading'

import { useSessionContext } from 'context/SessionContext'
import { useHeaderDispatch } from 'context/HeaderContext'
import { useAppDispatch } from 'context/AppContext'
import { useMediaContext } from "context/MediaContext"
import { useGroupsFiltersState, useGroupsFiltersDispatch } from 'context/GroupsContext'
import { useGroups } from 'hooks/useGroups'
import { syncGroup } from 'services/syncGroup'


const Groups = () => {

    const { t } = useTranslation()
    const { groupsState, groupsDispatch } = useGroups()
    const { total, page, size, userColumns, columns } = useGroupsFiltersState()
    const filtersDispatch = useGroupsFiltersDispatch()
    const appDispatch = useAppDispatch()
    const { lang, env } = useSessionContext()
    const { smView, mdView, lgView, xlView } = useMediaContext()

    const setHeader = useHeaderDispatch()

    useEffect(function () {
        setHeader({
            title: t('app.groupsTitle'),
            breadcrumb: [
                { to: '/', title: t('app.home')}
            ],
            config: true
        })
    }, [setHeader, t])


    useEffect(function () {
      if(!userColumns){
        const mediaCols = columns.map(c => {
          let show = true
          switch (c.priority) {
            case 'sm':
              if(smView) show = false
              break
            case 'md':
              if(smView || mdView) show = false
              break
            case 'lg':
              if(!lgView && !xlView) show = false
              break
            case 'xl':
              if(!xlView) show = false
              break
            default:
              show = true
          }
          return { ...c, show }
        })
        filtersDispatch({ type: c.SET_COLUMNS, payload: mediaCols })
      }
    }, [userColumns, smView, mdView, lgView, xlView]) // eslint-disable-line react-hooks/exhaustive-deps


    const handleChangePage = (page, evt) => {
        evt.preventDefault()
        filtersDispatch({ type: c.SET_PAGE, payload: page })
    }

    const handleResync = (id, evt) => {
        evt.preventDefault()
        groupsDispatch({ type: c.SYNC_GROUP_REQUEST, payload: id });
        syncGroup({lang, env, id})
            .then((data) => {
              if(data.type && data.type === 'error'){
                groupsDispatch({
                  type: c.SYNC_GROUP_ERROR,
                  payload: { id, error: data.message },
                })
              }else{
                groupsDispatch({
                  type: c.SYNC_GROUP_SUCCESS,
                  payload: {
                    id,
                    sync_status: data.sync_status,
                    sync_date: data.sync_date,
                    sync_message: data.sync_message,
                  },
                })
                filtersDispatch({ type: c.SET_PAGE, payload: page + 1 })
                filtersDispatch({ type: c.SET_PAGE, payload: page })
              }
            })
            .catch((error) => {
              console.log(error);
              groupsDispatch({
                type: c.SYNC_GROUP_FAILURE,
                payload: { id, error: error.message },
              })
              appDispatch({ type: c.SET_MESSAGES, payload: [error] })
            })
    }

    let allCols = true
    columns.forEach(function (c) {
      if(!c.show) allCols = false
    })

    const cols = [
      {
        id: 1,
        title: t("columns.sync"),
        sort: "sync_status",
      },
      {
        id: 2,
        title: t("columns.domainCode"),
        sort: "code",
      },
      {
        id: 3,
        title: t("columns.groupName"),
        sort: "group_name",
        width: allCols ? "13%" : null,
      },
      {
        id: 4,
        title: t("columns.praCampus"),
        sort: "n_pra_campus",
        width: allCols ? "7%" : null,
      },
      {
        id: 5,
        title: t("columns.pdcCampus"),
        sort: "n_pdc_campus",
        width: allCols ? "7%" : null,
      },
      {
        id: 6,
        title: t("columns.staffGroup"),
        sort: "n_staff_group",
        width: allCols ? lang === "es" ? "11%" : "9%" : null,
      },
      {
        id: 7,
        title: t("columns.studentsCampus"),
        sort: "n_students_campus",
        width: allCols ? "8%" : null,
      },
      {
        id: 8,
        title: t("columns.studentsGroup"),
        sort: "n_students_group",
        width: allCols ? "8%" : null,
      },
      {
        id: 9,
        title: t("columns.createDate"),
        sort: "creation_date",
      },
      {
        id: 10,
        title: t("columns.syncDate"),
        sort: "sync_date",
      },
      {
        id: 11,
        title: t("columns.aulaType"),
        sort: "classroom_type_key"
      },
      {
        id: 12,
        title: t("columns.aulaFolders"),
        sort: "folders_status",
      },
    ]


    if (groupsState.isLoading && groupsState.firstLoad) return <Loading />
    return (
        <ViewTransition>
          <GroupsFilters />
          <GroupsMenu cols={cols} allCols={allCols} />
          <GroupsTable cols={cols} rows={groupsState.groups} onResync={handleResync}/>
          <Pagination total={total} page={page} size={size} path="/groups" onChangePage={handleChangePage} />
          { groupsState.isLoading ? <Loading /> : null }
        </ViewTransition>
    )
}

export default Groups;
