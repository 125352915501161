import { OverlayTrigger, Tooltip } from "react-bootstrap";

const GenericTooltip = ({id, tooltip, className, children, placement= 'right'}) => {
  const popperConfig = {
    modifiers: [
      { 
        name: 'offset',
        options: {
          offset: [3, 3],
          enabled: true
        }
      }
    ]
  }
  return (
    <OverlayTrigger
      popperConfig={popperConfig}
      overlay={
        <Tooltip id={id} className={className}>
            {tooltip}
        </Tooltip>
      }
      
      placement={placement}
    >
      {children}
    </OverlayTrigger>
  )
}

export default GenericTooltip
