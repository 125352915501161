
import { useEffect, useReducer} from 'react'
import * as c from 'config/constants'

import { getGroups } from 'services/getGroups'

import { useGroupsFiltersState, useGroupsFiltersDispatch } from 'context/GroupsContext'
import { useSessionContext } from 'context/SessionContext'
import { useAppDispatch } from 'context/AppContext'

const initialState = {
  groups: [],
  firstLoad: true,
  isLoading: true,
  isError: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case c.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case c.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firstLoad: false,
        groups: [ ...action.payload ]
      }
    case c.FETCH_ERROR:
      return {
        ...state,
        isError: true,
        firstLoad: false,
        isLoading: false
      }
    case c.SYNC_GROUP_REQUEST:
        return {
          ...state,
          groups: state.groups.map(item => {
            return item.id + "" !== action.payload + ""
            ? item
            : { ...item, isSyncing: true};
          })
      }
    case c.SYNC_GROUP_SUCCESS:
        return {
          ...state,
          groups: state.groups.map((item) => {
            return item.id + "" !== action.payload.id + ""
              ? item
              : {
                  ...item,
                  sync_status: action.payload.sync_status,
                  sync_date: action.payload.sync_date,
                  sync_message: action.payload.sync_message,
                  isSyncing: false,
                }
          }),
        }
    case c.SYNC_GROUP_ERROR:
        return {
          ...state,
          groups: state.groups.map(item => {
            return item.id + "" !== action.payload.id + ""
            ? item
            : { ...item, sync_error: action.payload.error, sync_status: 0, isSyncing: false};
          })
        }
    case c.SYNC_GROUP_FAILURE:
        return {
            ...state,
            groups: state.groups.map(item => {
              return item.id + "" !== action.payload.id + ""
              ? item
              : { ...item, isSyncing: false};
            })
        }
    default:
      return state;
  }
}

export function useGroups() {
  const [state, dispatch] = useReducer(reducer, initialState)
  const {
    page,
    size,
    order,
    order_by,
    sync_status,
    code,
    group_name,
    semester,
    classroom_type,
    start_date_group,
    end_date_group,
    start_date_classroom,
    end_date_classroom,
  } = useGroupsFiltersState();

  const { lang, env } = useSessionContext()
  const gfDispatch = useGroupsFiltersDispatch()
  const appDispatch = useAppDispatch()

  useEffect(
    function () {

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })

      dispatch({ type: c.FETCH_REQUEST });
      getGroups({
        lang,
        env,
        page,
        size,
        order,
        order_by,
        sync_status,
        code,
        group_name,
        semester,
        classroom_type,
        start_date_group,
        end_date_group,
        start_date_classroom,
        end_date_classroom,
      })
        .then((res) => {
          if (res.type && res.type === "error") {
            dispatch({ type: c.FETCH_ERROR, payload: res });
            appDispatch({ type: c.SET_MESSAGES, payload: [res] })
          } else {
            const g = res.content.map(obj=> ({ ...obj, isSyncing: false }))
            dispatch({ type: c.FETCH_SUCCESS, payload: g })
            gfDispatch({ type: c.SET_TOTAL, payload: res.totalElements })
          }
        })
        .catch((error) => { 
          console.log(error);
          dispatch({ type: c.FETCH_ERROR, payload: error })
          appDispatch({ type: c.SET_MESSAGES, payload: [error] })
        })
    },
    [
      lang,
      env,
      page,
      size,
      order,
      order_by,
      sync_status,
      code,
      group_name,
      semester,
      classroom_type,
      start_date_group,
      end_date_group,
      start_date_classroom,
      end_date_classroom,
      gfDispatch,
      appDispatch
    ]
  )
  
  return { groupsState: state, groupsDispatch: dispatch }
}