import classNames from "classnames"

const Spinner = ({ label = "cargando...", type = "normal" }) => {
  const spinClass = classNames("spin", {
    "spin--small": type === "small",
    "spin--icon": type === "icon"
  })
  return (
    <div className={spinClass}>
      <span className="visually-hidden">{label}</span>
    </div>
  )
}

export default Spinner
