import {useEffect, useState} from "react";
import {useSessionContext} from "../context/SessionContext";

const Authentication = ({children}) => {

    const [authenticated, setAuthenticated] = useState(null);
    const { env } = useSessionContext();

    const buildLoginUrl = (oauthConfiguration) => {
        const uuid = require("uuid");
        const authorizeUrl = new URL(oauthConfiguration.oauth_server + "/oauth2/auth");

        authorizeUrl.searchParams.set("response_type", oauthConfiguration.response_type);
        authorizeUrl.searchParams.set("client_id", oauthConfiguration.client_id);
        authorizeUrl.searchParams.set("redirect_uri", oauthConfiguration.callback_url);
        authorizeUrl.searchParams.set("scope", oauthConfiguration.scope);
        authorizeUrl.searchParams.set("state", uuid.v4());
        authorizeUrl.searchParams.set("nonce", uuid.v4());
        return authorizeUrl.href;
    }

    const parseJWTToken = (accessToken) => {
        return JSON.parse(atob(accessToken.split('.')[1]));
    }

    const exchangeToken = async (oauthConfiguration, urlParams) => {
        const code = urlParams.get('code');
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(oauthConfiguration.client_id + ":" + urlParams.get('state'))}`
            },
            body: new URLSearchParams(({
                grant_type: "authorization_code",
                code: code,
                client_id: oauthConfiguration.client_id,
                redirect_uri: oauthConfiguration.callback_url
            }))
        };

        fetch(`${oauthConfiguration.oauth_server}/oauth2/token`, requestOptions)
            .then(response => response.json())
            .then(data => {
                data.valid_until = new Date().getTime() + (data.expires_in * 1000);
                localStorage.setItem('token', JSON.stringify(data));
                window.location.href = oauthConfiguration.redirect_url
                setAuthenticated(true);
            }).catch(error => {
            console.error(error);
            setAuthenticated(false);
        });
    }

    const tokenExistsAndNotExpired = (token) => {
        return token && token.valid_until > new Date().getTime();
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        if (!urlParams.has('code')) {
            window.token = JSON.parse(localStorage.getItem('token'));
        } else {
            // It has token, so we exchange our code for a token
            exchangeToken(env.oauth, urlParams).catch(error => {
                console.error('Error exchanging token', error);
                setAuthenticated(false);
            })
            return;
        }
        if (tokenExistsAndNotExpired(window.token)) {
            // If there's a token we use it to access the openid service.
            const jwtToken = parseJWTToken(window.token.id_token);
            const hasRequiredEntitlement = jwtToken.eduPersonEntitlement.some(entitlement => entitlement.includes(env.oauth.entitlement_role));
            setAuthenticated(hasRequiredEntitlement);
        } else {
            if (authenticated === null) {
                window.location.href = buildLoginUrl(env.oauth);
            }
        }
    },  [env, authenticated]);


    return (
        <>
            {authenticated ? children : 'Not authenticated'}
        </>
    )
}

export default Authentication
