
import { useEffect, useReducer } from 'react'
import * as c from 'config/constants'

import { getGroup } from 'services/getGroup'

import { useSessionContext } from 'context/SessionContext'
import { useAppDispatch } from 'context/AppContext'

const initialState = {
  group: {},
  isLoading: true,
  isSyncing: false,
  isError: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case c.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      };
    case c.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        group: { ...action.payload }
      };
    case c.FETCH_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false
      }
    case c.SYNC_GROUP_REQUEST:
      return {
        ...state,
        isSyncing: true
      }
    case c.SYNC_GROUP_SUCCESS:
      return {
        ...state,
        group: {
          ...state.group,
          sync_status: action.payload.sync_status,
          sync_date: action.payload.sync_date,
          sync_message: action.payload.sync_message,
        },
        isSyncing: false,
      }
    case c.SYNC_GROUP_ERROR:
      return {
        ...state,
        isSyncing: false,
        group: { ...state.group, sync_status: 0, sync_message: action.payload.error }
      }
    case c.SYNC_GROUP_FAILURE:
      return {
        ...state,
        isSyncing: false
      }
    default:
      return state
  }
}

export function useGroup(id, load = true) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { lang, env } = useSessionContext()
  const appDispatch = useAppDispatch()

  useEffect(
    function () {
      if(load){
      dispatch({ type: c.FETCH_REQUEST });
      getGroup({ lang, env, id })
        .then((data) => {
          if (data.type && data.type === "error") {
            dispatch({ type: c.FETCH_ERROR, payload: data });
            appDispatch({ type: c.SET_MESSAGES, payload: [data] })
          } else {
            dispatch({ type: c.FETCH_SUCCESS, payload: data })
            if(data.alerts){
              appDispatch({ type: c.SET_MESSAGES, payload: [...data.alerts] })
            }
          }
        })
        .catch((error) => {
          console.log(error)
          dispatch({ type: c.FETCH_ERROR, payload: error })
          appDispatch({ type: c.SET_MESSAGES, payload: [error] })
        })
      }
    },
    [lang, env, id, load, appDispatch]
  )
  

  return { groupState: state, groupDispatch: dispatch }
}