import { useState, useEffect, forwardRef, memo } from "react"
import { useTranslation } from "react-i18next"
import { format, formatISO, isValid} from "date-fns"

import DatePicker from "react-datepicker"

import { useSessionContext } from 'context/SessionContext'

const CustomInput = forwardRef(
  (
    {
      open,
      name,
      value,
      placeholderText,
      labelledBy,
      describedBy,
      onClick,
      onFocus,
      onBlur,
      onKeyDown,
      onChange,
    },
    ref
  ) => {
    return (
      <>
      <input
        type="text"
        className="form-item"
        name={name}
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        value={value}
        placeholder={placeholderText}
        aria-labelledby={labelledBy}
        aria-describedby={open ? describedBy : null}
        ref={ref}
      />
      <span className="icon icon--calendar-full icon--small"></span>
      </>
    )
  }
)
  
const renderDayContents = (day, date) => {
  const tooltipText = format(date, "dd MMMM yyyy")
  return <span title={tooltipText}>{day}</span>;
}

const CalendarHelp = (id) => {
  const {t} = useTranslation();
  return <div id={id} className="visually-hidden">{t('common.calendarHelp')}</div>
}

const InputDateRange = ({ name, start, end, labelledby, onChangeStart = f => f, onChangeEnd = f => f}) => {
    const {t} = useTranslation();
    const { dateFormat } = useSessionContext()
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(end)
    const [openStart, setOpenStart] = useState(false)
    const [openEnd, setOpenEnd] = useState(false)

    useEffect(
      function () {
        setStartDate(start)
        setEndDate(end)
      }, [start,end]
    )

    const handleChangeStart = () => {
      const formatDate = isValid(startDate) ? formatISO(startDate, { representation: 'date' }) : null
      onChangeStart(formatDate)
    }

    const handleChangeEnd = () => {
      const formatDate = isValid(endDate) ? formatISO(endDate, { representation: 'date' }) : null
      onChangeEnd(formatDate)
    }

    return (
        <>
        <label id={`${name}_start_lbl`} className="visually-hidden">{t("filters.startDate")}</label>   
        <div className="form-input--datepicker">
          <DatePicker
            dateFormat={dateFormat}
            name={`${name}_start`}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            maxDate={endDate ? endDate : undefined}
            selectsStart
            fixedHeight
            renderDayContents={renderDayContents}
            openToDate={startDate}
            showPopperArrow={true}
            onChange={date => setStartDate(date)}
            onCalendarOpen={open => setOpenStart(open)}
            onCalendarClose={open => {setOpenStart(open); handleChangeStart()}}
            customInput={<CustomInput placeholderText={t("filters.startDate")} labelledBy={`${name}_start_lbl ${labelledby}`} describedBy={`ui-help-start-${name}`} open={openStart}/>}
          >
            <CalendarHelp id={`ui-help-start-${name}`} />
          </DatePicker>
        </div>
        <label id={`${name}_end_lbl`} className="visually-hidden">{t("filters.endDate")}</label>   
        <div className="form-input--datepicker">
          <DatePicker
            dateFormat={dateFormat}
            name={`${name}_end`}
            selected={endDate}
            startDate={startDate}
            endDate={endDate}
            minDate={startDate ? startDate : undefined}
            selectsEnd
            fixedHeight
            openToDate={endDate}
            showPopperArrow={true}
            onChange={date => setEndDate(date)}
            onCalendarOpen={open => setOpenEnd(open)}
            onCalendarClose={open => {setOpenEnd(open); handleChangeEnd()}}
            customInput={<CustomInput placeholderText={t("filters.endDate")} labelledBy={`${name}_end_lbl ${labelledby}`} describedBy={`ui-help-end-${name}`} open={openEnd}/>}
          >
            <CalendarHelp id={`ui-help-end-${name}`} />
          </DatePicker>
        </div>
      </>
    )
}

export default memo(InputDateRange)