
import { useReducer, useEffect, useCallback } from 'react'
import * as c from 'config/constants'

import { getConfig } from 'services/getConfig'
import { saveConfig } from 'services/saveConfig'
import { useSessionContext } from 'context/SessionContext'
import { useAppDispatch } from 'context/AppContext'

const initialState = {
  data: {},
  formData: { 
    folder_property_message: '',
    expire_garbage_value: 0,
    expire_garbage_options: [],
    can_manage_users: [],
    can_manage_users_added: [],
    can_manage_users_deleted: [],
    classroom_types: [],
    classroom_types_edit: [],
    classroom_types_deleted: [],
    nodes_lectius : '',
    nodes_finalitzacio : '',
    nodes_esborrat : '',
    nodes: [
      {id: 1, key: c.NODES_LECTIUS, value: '', isEdit: false, isCancel: false },
      {id: 2, key: c.NODES_FINALITZACIO, value: '', isEdit: false, isCancel: false },
      {id: 3, key: c.NODES_ESBORRAT, value: '', isEdit: false, isCancel: false },
    ],
    classroom_node_value: '',
    classroom_type_key: '',
    classroom_type_expire: '',
    add_manage_users: '',
    valid_add_users: false,
    new_class_index: 0,
    new_user_index: 0
  },
  isSaving: false,
  isLoading: true,
  isError: false,
  isUpdated: false,
  isUpdatedRisky: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case c.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSaving: false,
        isError: false
      }
    case c.FETCH_SUCCESS:
      return {
        ...state,
        data: {...action.payload},
        formData: {
          ...state.formData, 
          ...action.payload,
          folder_property_message: action.payload.folder_property_message ? action.payload.folder_property_message : '',
          classroom_types_edit: action.payload.classroom_types ? action.payload.classroom_types.map(item => {
            return { ...item, isEdit: false, isCancel: false };
          }) : [],
          classroom_types_deleted: [],
          nodes: [
            {key: c.NODES_LECTIUS, value: action.payload.nodes_lectius, isEdit: false, isCancel: false },
            {key: c.NODES_FINALITZACIO, value: action.payload.nodes_finalitzacio, isEdit: false, isCancel: false },
            {key: c.NODES_ESBORRAT, value: action.payload.nodes_esborrat, isEdit: false, isCancel: false },
          ],
          add_manage_users: '',
          valid_add_users: false,
          can_manage_users_added: [],
          can_manage_users_deleted: [],
          classroom_type_key: '',
          classroom_type_expire: '',
          classroom_node_value: '',
          new_class_index: 0,
          new_user_index: 0
        },
        isLoading: false,
        isError: false,
      }
    case c.FETCH_ERROR:
      return {
        ...state,
        isError: true,
        isSaving: false,
        isLoading: false
      }
    case c.SAVE_REQUEST:
      return {
        ...state,
        error: null,
        isLoading: false,
        isError: false,
        isUpdated: false,
        isSaving: true,
        formData: {
          ...state.formData,
          can_manage_users_added: state.formData.can_manage_users.filter(user => user.isNew).map(user => user.email),
          classroom_types: state.formData.classroom_types_edit.map(item => {
            return {
              id: item.id,
              key: item.key,
              expire: item.expire,
              isNew: item.isNew || null,
              isUpdated: (item.isUpdated && !item.isNew) || null
            }
          }),
          nodes_finalitzacio: state.formData.nodes.filter(node => node.key === c.NODES_FINALITZACIO)[0].value,
          nodes_lectius: state.formData.nodes.filter(node => node.key === c.NODES_LECTIUS)[0].value,
          nodes_esborrat: state.formData.nodes.filter(node => node.key === c.NODES_ESBORRAT)[0].value,
        }
      }
    case c.SAVE_SUCCESS:
      return {
        ...state,
        isSaving: false,
      }
    case c.SAVE_ERROR:
      return {
        ...state,
        //isError: true, // NOTA: dejamos volver a guardar y solo activamos isError cuando hay un fallo de red en el get inicial o similar
        isSaving: false
      }
    case c.UPDATE_FIELD:
      return {
        ...state,
        isUpdated:
          action.payload.field === "add_manage_users" ||
          action.payload.field === "classroom_type_key" ||
          action.payload.field === "classroom_type_expire"
            ? state.isUpdated
            : true,
        formData: {
          ...state.formData,
          [action.payload.field]: action.payload.value,
        },
      }
    case c.EDIT_AULA_TYPE:
      return {
        ...state,
        formData: {
          ...state.formData,
          classroom_types_edit: state.formData.classroom_types_edit.map(item => {
            return item.id + "" !== action.payload + ""
            ? item
            : { ...item, prev_key: item.key, prev_expire: item.expire, isEdit: true, isCancel: false};
          })
        },
      }
    case c.DEL_AULA_TYPE:
      return {
        ...state,
        isUpdated: true,
        formData: {
          ...state.formData,
          classroom_types_deleted: [ ...state.formData.classroom_types_deleted, action.payload ],
          classroom_types_edit: [...state.formData.classroom_types_edit.filter(item => item.id + "" !== action.payload + "")]
        },
      }
    case c.UPDATE_AULA_TYPE:
      return {
        ...state,
        formData: {
          ...state.formData,
          classroom_types_edit: state.formData.classroom_types_edit.map(item => {
            return item.id + "" !== action.payload.id + ""
            ? item
            : { ...item, [action.payload.field]: action.payload.value }
          })
        },
      }
    case c.CANCEL_AULA_TYPE:
      return {
        ...state,
        formData: {
          ...state.formData,
          classroom_types_edit: state.formData.classroom_types_edit.map(item => {
            return item.id + "" !== action.payload + ""
            ? item
            : { ...item, key: item.prev_key, expire: item.prev_expire, isEdit: false, isCancel: true }
          })
        },
      }
    case c.ACCEPT_AULA_TYPE:
      return {
        ...state,
        isUpdated: true,
        formData: {
          ...state.formData,
          classroom_types_edit: state.formData.classroom_types_edit.map(item => {
            return item.id + "" !== action.payload + ""
            ? item
            : { ...item, isEdit: false, isUpdated: true }
          })
        },
      }
    case c.ADD_AULA_TYPE:
      return {
        ...state,
        isUpdated: true,
        formData: {
          ...state.formData,
          new_class_index: state.formData.new_class_index + 1,
          classroom_type_key: '',
          classroom_type_expire: '',
          classroom_types_edit: [
            ...state.formData.classroom_types_edit.map(item => {
              return { ...item, isCancel: false }
            }),
            {
              id: '_new_' + state.formData.new_class_index, 
              key: state.formData.classroom_type_key, 
              expire: state.formData.classroom_type_expire,
              isNew: true
            }
          ]
        },
      }
    case c.EDIT_AULA_NODE:
      return {
        ...state,
        formData: {
          ...state.formData,
          nodes: state.formData.nodes.map(item => {
            return item.key + "" !== action.payload + ""
            ? item
            : { ...item, prev_value: item.value, isEdit: true, isCancel: false};
          })
        },
      }
    case c.UPDATE_AULA_NODE:
      return {
        ...state,
        formData: {
          ...state.formData,
          nodes: state.formData.nodes.map(item => {
            return item.key + "" !== action.payload.key + ""
            ? item
            : { ...item, [action.payload.field]: action.payload.value };
          })
        },
      }
    case c.CANCEL_AULA_NODE:
      return {
        ...state,
        formData: {
          ...state.formData,
          nodes: state.formData.nodes.map(item => {
            return item.key + "" !== action.payload + ""
            ? item
            : { ...item, value: item.prev_value, isEdit: false, isCancel: true }
          })
        },
      }
    case c.ACCEPT_AULA_NODE:
      return {
        ...state,
        isUpdated: true,
        isUpdatedRisky: true,
        formData: {
          ...state.formData,
          nodes: state.formData.nodes.map(item => {
            return item.key + "" !== action.payload + ""
            ? item
            : { ...item, isEdit: false, isUpdated: true }
          })
        },
      }
    case c.VALID_USERS:
      return {
        ...state,
        formData: {
          ...state.formData,
          valid_add_users: action.payload,
        }
      }
    case c.ADD_USERS:
      let user_index = state.formData.new_user_index;
      const users_added = action.payload.map(user => {
        user_index += 1
        return { id: '_new_' + user_index , email: user , isNew: true }
      })
      return {
        ...state,
        isUpdated: true,
        formData: {
          ...state.formData,
          add_manage_users: '',
          valid_add_users: false,
          new_user_index: user_index,
          can_manage_users: [...state.formData.can_manage_users, ...users_added],
        }
      }
    case c.DEL_USER:
      return {
        ...state,
        isUpdated: true,
        formData: {
          ...state.formData,
          can_manage_users: state.formData.can_manage_users.filter(item => item.id !== action.payload.id),
          can_manage_users_deleted: action.payload.isNew ? [...state.formData.can_manage_users_deleted] : [...state.formData.can_manage_users_deleted, action.payload.id]
        }
      }
    default:
      return state
  }
}

export function useConfig() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const context = useSessionContext()
  const appDispatch = useAppDispatch()
  const { lang, env } = context;

  const loadConfig = useCallback(() => {
    dispatch({ type: c.FETCH_REQUEST });
    getConfig({ lang: lang, env: env })
      .then((data) => {
        if (data.type && data.type === "error") {
          dispatch({ type: c.FETCH_ERROR, payload: data });
          appDispatch({ type: c.SET_MESSAGES, payload: [data] })
        } else {
          dispatch({ type: c.FETCH_SUCCESS, payload: data });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: c.FETCH_ERROR, payload: error });
        appDispatch({ type: c.SET_MESSAGES, payload: [error] })
      });
  },[lang, env, appDispatch]);


  useEffect(() => {
    loadConfig()
  }, [loadConfig])

  useEffect(
    function () {
      if(state.isSaving){
        saveConfig({ context, data: state.formData })
          .then((data) => {
            if (data.type && data.type === "error") {
              dispatch({ type: c.SAVE_ERROR, payload: data });
              appDispatch({ type: c.SET_MESSAGES, payload: [data] })
            }else{
              //dispatch({ type: c.SAVE_SUCCESS, payload: data })
              appDispatch({ type: c.SET_MESSAGES, payload: [data] })
              loadConfig();
            }
          })
          .catch((error) => {
            console.log(error);
            dispatch({ type: c.SAVE_ERROR, payload: error });
            appDispatch({ type: c.SET_MESSAGES, payload: [error] })
          });
      }
    },
    [context, state, appDispatch, loadConfig]
  )
  
  return { configState: state, configDispatch: dispatch }
}