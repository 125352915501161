import { useEffect, useState, useMemo, createRef, forwardRef } from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import classNames from "classnames"
import * as c from 'config/constants'


import ViewTransition from "components/ViewTransition"
import IconTooltip from "components/IconTooltip"
import Loading from "components/Loading"
import ConfirmDialog from "components/ConfirmDialog";

import { useHeaderDispatch } from 'context/HeaderContext'
import { useConfig } from 'hooks/useConfig'

const ConfigMenu = ({ isSaving = false, isUpdated = false, isError = false, onSave = f => f, onCancel = f => f }) => {
    const {t} = useTranslation()
    const save_btn_cls = classNames("btn btn--primary btn--large btn--save", {
        'btn--saving': isSaving,
        'disabled': !isUpdated || isError
    })
    const save_icon_cls = classNames("icon icon--save-full icon--small icon--before", {
        'saving': isSaving,
    })
    return (
        <nav>
            <ul className="clearfix py-3 mb-4 list--unstyled list--inline list--nav ruler ruler--thin">
                <li>
                    <button type="submit" className={save_btn_cls} onClick={onSave} disabled={!isUpdated || isError}>
                        <span className={save_icon_cls} aria-hidden="true"></span>
                        <span className="btn-label">{t('actions.save')}</span>
                    </button>
                </li>
                <li>
                    <button type="button" className="btn btn--secondary btn--large" onClick={onCancel}>
                        <span className="icon icon--close icon--small icon--before" aria-hidden="true"></span>
                        <span className="btn-label">{t('actions.cancel')}</span>
                    </button>
                </li>
            </ul>
        </nav>
    )
}


const RowType =  forwardRef(
    ({
    id,
    clau = '',
    expire = '',
    isEdit = false,
    isCancel = false,
    onChange = f => f,
    onDelete = f => f,
    onAdd = f => f,
    onEdit = f => f,
    onAccept = f => f,
    onCancel = f => f
    }, ref) => {
    const {t} = useTranslation();

    const {refInput, refBtn} = ref || {}
    useEffect(function () {
        if (isEdit) refInput && refInput.current && refInput.current.focus()
        if (isCancel) refBtn && refBtn.current && refBtn.current.focus()
    },[isEdit, isCancel, refInput, refBtn])

    return (
        <tr>
            <td>
                {isEdit ?
                <>
                    <label className="visually-hidden" htmlFor={`classroom_type_key${id}`}>{t("config.lblKey")}</label>
                    <input
                        type="text"
                        id={`classroom_type_key${id}`}
                        name={`classroom_type_key${id}`}
                        placeholder={t("config.lblKey")}
                        className="form-item"
                        value={clau}
                        onChange={e => onChange(e, 'key', id)}
                        ref={id ? refInput : null}
                    />
                </>
                : <span className="py-2 d-inline-block">{clau}</span>}
            </td>
            <td>
                {isEdit ?
                <>
                    <label className="visually-hidden" htmlFor={`classroom_type_expire${id}`}>{t("config.lblExpiration")}</label>
                    <input
                        type="number"
                        min="-1"
                        id={`classroom_type_expire${id}`}
                        name={`classroom_type_expire${id}`}
                        placeholder={t("config.lblExpiration")}
                        className="mr-1 form-item form-item--inline"
                        value={expire}
                        aria-describedby={`classroom_type_key_weeks${id}`}
                        onChange={e => onChange(e, 'expire', id)}
                        onInput={e => {
                            if(e.target.value !== ''){
                                const n = Math.max(-1, parseInt(e.target.value, 10))
                                e.target.value = (n >= -1 ? n : '').toString()
                            }
                        }}
                    />
                </>
                : <span className="py-2 d-inline-block">{expire}</span>}
                <span className="ml-1" id={`classroom_type_key_weeks${id}`}>{t("config.weeks")}</span>
            </td>
            <td>
                {id ?
                    isEdit ?
                    <>
                        <button type="button" className="mr-2 btn btn--secondary" onClick={() => onCancel(id)}>
                            <span className="icon icon--close icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.cancel")}
                        </button>
                        <button type="button" className="btn btn--primary" onClick={() => onAccept(id)} disabled={ clau === '' || expire === '' }>
                            <span className="icon icon--checkmark icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.accept")}
                        </button>
                    </>
                    :
                    <>
                        <a href="#/" role="button" className="mr-4 btnlink btnlink--regular" ref={refBtn} onClick={(e) => {e.preventDefault(); onEdit(id)}}>
                            <span className="icon icon--edit-pencil icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.edit")}
                        </a>
                        <a href="#/" type="button" className="btnlink btnlink--regular" onClick={(e) => {e.preventDefault(); onDelete(id, clau)}}>
                            <span className="icon icon--trash icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.delete")}
                        </a>
                    </>
                    :
                    <button type="button" className="btn btn--primary" onClick={onAdd} disabled={ clau === '' || expire === '' }>
                        <span className="icon icon--plus icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.add")}
                    </button>
                }
            </td>
        </tr>
    )
})


const RowNode =  forwardRef(
    ({
    clau,
    value,
    isEdit = false,
    isCancel = false,
    onChange = f => f,
    onEdit = f => f,
    onAccept = f => f,
    onCancel = f => f
    }, ref) => {
    const {t} = useTranslation();

    const {refInput, refBtn} = ref || {}
    useEffect(function () {
        let timer = null
        if (isEdit) {
            timer = setTimeout(() => {
                refInput && refInput.current && refInput.current.focus()
            }, 250);
        }
        if (isCancel) refBtn && refBtn.current && refBtn.current.focus()
        return () => clearTimeout(timer);
    },[isEdit, isCancel, refInput, refBtn])

    return (
        <tr>
            <td>
                <span className="py-2 d-inline-block">{clau}</span>
            </td>
            <td>
                {isEdit ?
                <>
                    <label className="visually-hidden" htmlFor={`classroom_node_value${clau}`}>{t("config.lblNodes")}</label>
                    <input
                        ref={refInput}
                        type="text"
                        id={`classroom_node_value_${clau}`}
                        name={`classroom_node_value_${clau}`}
                        placeholder={t("config.lblNodes")}
                        className="form-item"
                        value={value}
                        onChange={e => onChange(e, 'value', clau)}
                    />
                </>
                : <span className="py-2 d-inline-block">{value}</span>}
            </td>
            <td>
                {isEdit ?
                <>
                    <button type="button" className="mr-2 btn btn--secondary" onClick={() => onCancel(clau)}>
                        <span className="icon icon--close icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.cancel")}
                    </button>
                    <button type="button" className="btn btn--primary" onClick={() => onAccept(clau)} disabled={ value === '' }>
                        <span className="icon icon--checkmark icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.accept")}
                    </button>
                </>
                :
                    <a href="#/" role="button" className="mr-4 btnlink btnlink--regular" ref={refBtn} onClick={(e) => {e.preventDefault(); onEdit(clau)}}>
                        <span className="icon icon--edit-pencil icon--xsmall icon--before" aria-hidden="true"></span>{t("actions.edit")}
                    </a>
                }
            </td>
        </tr>
    )
})


const Config = () => {

    const {t} = useTranslation()
    const history = useHistory()

    const setHeader = useHeaderDispatch()
    const { configState, configDispatch } = useConfig()

    const [ dialog, setDialog ] = useState({})

    const typeRefs = useMemo(() =>
        Array.from({length: configState.formData.classroom_types_edit.length }).map(() => {
            return {
                refInput: createRef(),
                refBtn: createRef()
            }
        }),
        [configState.formData.classroom_types_edit.length]
    )

    const nodeRefs = useMemo(() =>
        Array.from({length: configState.formData.nodes.length }).map(() => {
            return {
                refInput: createRef(),
                refBtn: createRef()
            }
        }),
        [configState.formData.nodes.length]
    )

    const { isLoading, isSaving, isUpdated, isError, formData } = configState
    const { dialogShow, dialogTitle, dialogBody, dialogCancel, dialogConfirm } = dialog

    useEffect(function () {
        setDialog({
            dialogShow: false,
            dialogExited: false,
            dialogSrc: null,
            dialogType: null,
            dialogTitle: null,
            dialogBody: null,
            dialogCancel: '',
            dialogConfirm: ''
        })
        setHeader({
            title:  t('app.configTitle'),
            breadcrumb: [
                { to: '/', title: t('app.groupsTitle')},
                { to: `/config`, title: t('app.configTitle')}
            ],
            config: false
        })
    }, [setHeader, t])


    const handleSubmit = (evt) => {
        evt.preventDefault()
        if (isUpdated) {
            setDialog({
                ...dialog,
                dialogShow: true,
                dialogPayload: null,
                dialogCancelAction: null,
                dialogConfirmAction: c.SAVE_REQUEST,
                dialogTitle: t('modals.saveChangesPrompt'),
                dialogBody: null,
                dialogCancel: t('modals.saveNo'),
                dialogConfirm: t('modals.saveYes')
            })
        } else {
            history.push('/');
        }
    }

    const handleCancel = (evt) => {
        evt.preventDefault()
        if (isUpdated) {
            setDialog({
                ...dialog,
                dialogShow: true,
                dialogPayload: null,
                dialogCancelAction: null,
                dialogConfirmAction: c.HISTORY_BACK,
                dialogTitle: t('modals.cancelChangesPrompt'),
                dialogBody: null,
                dialogCancel: t('modals.cancelNo'),
                dialogConfirm: t('modals.cancelYes')
            })
        } else {
            history.push('/');
        }
    }


    const handleChangeField = (evt) => {
        configDispatch({ type: c.UPDATE_FIELD, payload: {field: evt.target.name, value: evt.target.value} })
    }

    const handleChangeAulaType = (evt, field, id) => {
        configDispatch({ type: c.UPDATE_AULA_TYPE, payload: { field, id, value: evt.target.value } })
    }

    const handleEditAulaType = (id) => {
        configDispatch({ type: c.EDIT_AULA_TYPE, payload: id })
    }

    const handleAcceptAulaType = (id) => {
        configDispatch({ type: c.ACCEPT_AULA_TYPE, payload: id })
    }

    const handleCancelAulaType = (id) => {
        configDispatch({ type: c.CANCEL_AULA_TYPE, payload: id })
    }

    const handleAddAulaType = () => {
        configDispatch({ type: c.ADD_AULA_TYPE })
    }

    const handleChangeNode = (evt, field, key) => {
        configDispatch({ type: c.UPDATE_AULA_NODE, payload: { field, key, value: evt.target.value } })
    }

    const handleEditNode = (key) => {
        setDialog({
            ...dialog,
            dialogShow: true,
            dialogPayload: key,
            dialogCancelAction: null,
            dialogConfirmAction: c.EDIT_AULA_NODE,
            dialogTitle: t('modals.attention'),
            dialogBody: t('modals.editNodePrompt'),
            dialogCancel: t('actions.cancel'),
            dialogConfirm: t('actions.accept')
        })
    }

    const handleAcceptNode = (key) => {
        configDispatch({ type: c.ACCEPT_AULA_NODE, payload: key })
    }

    const handleCancelNode = (key) => {
        configDispatch({ type: c.CANCEL_AULA_NODE, payload: key })
    }

    const handleDelAulaType = (id, title) => {
        setDialog({
            ...dialog,
            dialogShow: true,
            dialogPayload: id,
            dialogCancelAction: null,
            dialogConfirmAction: c.DEL_AULA_TYPE,
            dialogTitle: <Trans i18nKey="modals.deleteTypePrompt" title={title}>Sure you want to delete classroom type <strong>{{title}}</strong>?</Trans>,
            dialogBody: null,
            dialogCancel: t('modals.deleteNo'),
            dialogConfirm: t('modals.deleteYes')
        })
    }

    const handleDialogCancel = () => {
        setDialog({
            ...dialog,
            dialogShow: false
        })
        if(dialog.dialogCancelAction){
            configDispatch({ type: dialog.dialogCancelAction, payload: dialog.dialogPayload })
        }
    }

    const handleDialogConfirm = () => {
        setDialog({
            ...dialog,
            dialogShow: false
        })
        if(dialog.dialogConfirmAction){
            if(dialog.dialogConfirmAction === c.HISTORY_BACK){
                history.push('/');
            }else{
                configDispatch({ type: dialog.dialogConfirmAction, payload: dialog.dialogPayload })
            }
        }
    }

    if (isLoading ) return <Loading />

    return (
        <ViewTransition>
            <form
                className="mb-3 form--inverse form--config mb-md-2"
                noValidate
                onSubmit={handleSubmit}
            >
                <ConfigMenu
                    isSaving={isSaving}
                    isUpdated={isUpdated}
                    isError={isError}
                    onSave={handleSubmit}
                    onCancel={handleCancel}
                />

                <div className="row">

                    <div className="col-lg-12">
                        <div className="mb-5 form-group mr-lg-2 pr-lg-1">
                            <label htmlFor="folder_property_message">{t("config.lblMessage")}</label>
                            <textarea
                                id="folder_property_message"
                                name="folder_property_message"
                                placeholder={t("config.writeMessage")}
                                className="form-item"
                                value={formData.folder_property_message}
                                onChange={handleChangeField}
                            />
                        </div>
                    </div>

                </div>

                <fieldset className="mb-5 form-group">
                    <legend className="label">{t("config.lblAulaTypes")}</legend>
                    <table className="table table--borders table--types">
                        <colgroup>
                            <col style={{width: '40%'}}/>
                            <col style={{width: '37%'}}/>
                            <col style={{width: '23%'}}/>
                        </colgroup>
                        <thead className="table__header">
                            <tr>
                                <th>{t("config.lblKey")}</th>
                                <th>
                                    {t("config.lblExpiration")}{' '}
                                    <IconTooltip
                                        tooltip={t("config.tipExpiration")}
                                        id="tooltip_config_expiration"
                                        icon="icon--info-full icon--normal"
                                        placement="top"
                                    />
                                </th>
                                <th>{t("config.lblActions")}</th>
                            </tr>
                        </thead>
                        <tbody className="mb-0 form-group">
                            {formData.classroom_types_edit.map((item, index) => (
                                <RowType
                                    key={`classroom_type_${item.id}`}
                                    ref={typeRefs[index]}
                                    id={item.id}
                                    clau={item.key}
                                    expire={item.expire}
                                    isEdit={item.isEdit}
                                    isCancel={item.isCancel}
                                    onEdit={handleEditAulaType}
                                    onChange={handleChangeAulaType}
                                    onDelete={handleDelAulaType}
                                    onAccept={handleAcceptAulaType}
                                    onCancel={handleCancelAulaType} />
                            ))}
                            <RowType
                                id=""
                                clau={formData.classroom_type_key}
                                expire={formData.classroom_type_expire}
                                isEdit={true}
                                onChange={handleChangeField}
                                onAdd={handleAddAulaType}  />
                        </tbody>
                    </table>
                </fieldset>

                <fieldset className="mb-5">
                    <legend className="label">{t("config.lblAulaNodes")}</legend>
                    <table className="table table--borders table--nodes">
                        <colgroup>
                            <col style={{width: '25%'}}/>
                            <col style={{width: '52%'}}/>
                            <col style={{width: '23%'}}/>
                        </colgroup>
                        <thead className="table__header">
                            <tr>
                                <th>{t("config.lblNode")}</th>
                                <th>{t("config.lblNodes")}</th>
                                <th>{t("config.lblActions")}</th>
                            </tr>
                        </thead>
                        <tbody className="mb-0 form-group">
                            {formData.nodes.map((item, index) => (
                                <RowNode
                                    key={`classroom_node_${item.key}`}
                                    ref={nodeRefs[index]}
                                    clau={item.key}
                                    value={item.value}
                                    isEdit={item.isEdit}
                                    isCancel={item.isCancel}
                                    onEdit={handleEditNode}
                                    onChange={handleChangeNode}
                                    onAccept={handleAcceptNode}
                                    onCancel={handleCancelNode}
                                    />
                            ))}
                        </tbody>
                    </table>
                </fieldset>

                <div className="row">
                    <div className="col-lg-6">
                        <div className="mb-5 form-group mr-lg-2 pr-lg-1">
                            <label htmlFor="expire_garbage_value" className="select--custom">
                                <span className="label">{t("config.lblExpirationGarbage")}</span>
                                <select
                                    id="expire_garbage_value"
                                    name="expire_garbage_value"
                                    className="form-item"
                                    onChange={handleChangeField}
                                    value={formData.expire_garbage_value}
                                    >
                                    {
                                        formData.expire_garbage_options.map((item) => {
                                        return <option value={item} key={`garbage_option_${item}`}>{item} {t("config.weeks")}</option>
                                        })
                                    }
                                </select>
                            </label>
                        </div>
                    </div>
                </div>

                <ConfigMenu
                    isSaving={isSaving}
                    isUpdated={isUpdated}
                    isError={isError}
                    onSave={handleSubmit}
                    onCancel={handleCancel}
                />

                <ConfirmDialog
                    show={dialogShow}
                    title={dialogTitle}
                    body={dialogBody}
                    confirm={dialogConfirm}
                    cancel={dialogCancel}
                    onConfirm={handleDialogConfirm}
                    onCancel={handleDialogCancel}
                />

                { isSaving && <Loading />}
            </form>
        </ViewTransition>
    )
}

export default Config;
