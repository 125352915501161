import { useContext, useReducer, createContext } from 'react'
import * as c from 'config/constants'

const AppStateContext = createContext()
const AppDispatchContext = createContext()

const initialState = { 
  group_id: null,
  group_name: null,
  messages: []
}

function appReducer(state, action) {
  switch (action.type) {
    case c.SET_GROUP:
      return {
        ...state,
        ...action.payload
      }
    case c.SET_MESSAGES:
      return {
        ...state,
        messages: [...action.payload]
      }
    case c.REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages[action.payload] ? 
          [...state.messages.slice(0, action.payload), ...state.messages.slice(action.payload + 1)] :
          [...state.messages]
      }
    case c.RESET_MESSAGES:
      return {
        ...state,
        messages: []
      }
    default:
      return state
  }
}

function AppProvider({ children }) {
  const [state, dispatch] = useReducer(appReducer, initialState)
  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

function useAppState() {
  const context = useContext(AppStateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider')
  }
  return context
}

function useAppDispatch() {
  const context = useContext(AppDispatchContext)
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider')
  }
  return context
}


export {AppProvider, useAppState, useAppDispatch}