
import { useEffect, useReducer } from 'react'
import * as c from 'config/constants'

import { getFilters } from 'services/getFilters'
import { useSessionContext } from 'context/SessionContext'

const initialState = {
  semesters: [],
  classroom_types: [],
  isLoading: true,
  isError: false
}
const reducer = (state, action) => {
  switch (action.type) {
    case c.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case c.FETCH_SUCCESS:
      return {
        ...state,
        isLoading: false,
        ...action.payload
      }
    case c.FETCH_ERROR:
      return {
        ...state,
        isError: true,
        isLoading: false
      }
    default:
      return state
  }
}

export function useFilters() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { lang, env } = useSessionContext()


  useEffect(
    function () {
      let suscribed = true
      dispatch({ type: c.FETCH_REQUEST });
      getFilters({ lang, env })
        .then((data) => {
          if(suscribed){
            if (data.type && data.type === "error") {
              dispatch({ type: c.FETCH_ERROR, payload: data });
            } else {
              dispatch({ type: c.FETCH_SUCCESS, payload: data });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          if (suscribed){
            dispatch({ type: c.FETCH_ERROR, payload: error })
          }
        })
      return () => suscribed = false
    },[lang, env]
  )

  return { filtersState: state, filtersDispatch: dispatch }
}