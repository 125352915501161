import { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useMediaContext } from 'context/MediaContext';

export function useStickyTable () {
  const fixedRef = useRef()
  const normalRef = useRef()
  const wrapperRef = useRef()
  const fixedWrapperRef = useRef()
  const { smView } = useMediaContext()

  const { ref, inView } = useInView({
    initialInView: true,
    rootMargin: `${smView ? '-167px' : '-207px'} 0px 0px 0px`,
  })

  const showSticky = () => {

    if (!fixedRef.current || !normalRef.current) return

    fixedRef.current.classList.add('is-sticky')

    const normalHead = normalRef.current.querySelector(".table__header")
    const fixedHead = fixedRef.current.querySelector(".table-sticky-container")
    const normalCols = normalRef.current.querySelectorAll(".table__cell-wrapper") || []
    const fixedCols = fixedRef.current.querySelectorAll(".table__cell-wrapper") || []

    if(normalHead){
      const { height } = normalHead.getBoundingClientRect() || { height: 62 }
      if(fixedHead) fixedHead.style.height = `${height + 4}px`
    }

    normalCols.forEach((col,index) => {
      const { width } = col.getBoundingClientRect() || {}
      if (width) {
        fixedCols[index].style.width = `${width}px`
      }
    })

  };

  const hideSticky = () => {

    if (!fixedRef.current) return
    
    const fixedCols = fixedRef.current.querySelectorAll(".table__cell-wrapper") || []
    fixedCols.forEach((col) => { col.style.width = ''})

    fixedRef.current.classList.remove('is-sticky')

  };

  const handleScroll = (evt, another) => {
    if(another) another.scrollLeft = evt.target.scrollLeft
  }

  useEffect(() => {

    const wrapper = wrapperRef.current
    const fixedWrapper = fixedWrapperRef.current

    if (fixedRef.current && normalRef.current) {
      wrapper && wrapper.addEventListener('scroll', (evt) => handleScroll(evt, fixedWrapper))
      fixedWrapper && fixedWrapper.addEventListener('scroll', (evt) => handleScroll(evt, wrapper))
      if(inView){
        hideSticky()
      }else{
        showSticky()
      }
    }

    return () => {
      wrapper && wrapper.removeEventListener('scroll', handleScroll)
      fixedWrapper && fixedWrapper.removeEventListener('scroll', handleScroll)
    }

  })

  return {ref, fixedRef, normalRef, wrapperRef, fixedWrapperRef, inView}
}