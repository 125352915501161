// App default config
export const PER_PAGE_LIMITS = [10, 15, 25]
export const PER_PAGE_DEFAULT = 15
export const EXPORT_LIMIT = 500
export const GROUPS_DEFAULT_ORDER = 'asc'
export const GROUPS_DEFAULT_ORDER_BY = 'code'
export const HISTORIC_DEFAULT_ORDER = 'desc'
export const HISTORIC_DEFAULT_ORDER_BY = 'date'
export const FOLDERS_DEFAULT_ORDER = 'asc'
export const FOLDERS_DEFAULT_ORDER_BY = 'type'

// generic action reducers
export const REMOVE_MESSAGE = "remove_message"
export const RESET_MESSAGES = "reset_messages"
export const SET_MESSAGES = "set_messages"
export const SET_LOADING = "set_loading"
export const SET_SAVING = "set_saving"
export const EXPAND_COLLAPSE = "expand_collapse"

export const FETCH_REQUEST = "fetch_request"
export const FETCH_SUCCESS = "fetch_success"
export const FETCH_ERROR = "fetch_error"
export const SAVE_REQUEST = "save_request"
export const SAVE_SUCCESS = "save_success"
export const SAVE_ERROR = "save_error"
export const HISTORY_BACK = "history_back"

// Config action reducers
export const UPDATE_FORM = "update_form"
export const UPDATE_FIELD = "update_field"
export const EDIT_AULA_TYPE = "edit_aula_type"
export const EDIT_AULA_NODE = "edit_aula_node"
export const CANCEL_AULA_TYPE = "cancel_aula_type"
export const CANCEL_AULA_NODE = "cancel_aula_node"
export const ACCEPT_AULA_TYPE = "accept_aula_type"
export const ACCEPT_AULA_NODE = "accept_aula_node"
export const UPDATE_AULA_TYPE = "update_aula_type"
export const UPDATE_AULA_NODE = "update_aula_node"
export const ADD_AULA_TYPE = "add_aula_type"
export const DEL_AULA_TYPE = "del_aula_type"
export const ADD_USERS = "add_users"
export const DEL_USER = "del_user"
export const VALID_USERS = "valid_users"
export const SAVE_CONFIG = "save_config"

// Config Nodes
export const NODES_LECTIUS = "nodes_lectius"
export const NODES_FINALITZACIO = "nodes_finalitzacio"
export const NODES_ESBORRAT = "nodes_esborrat"

// Groups action reducers
export const SYNC_GROUP_REQUEST = "sync_group_request"
export const SYNC_GROUP_SUCCESS = "sync_group_success"
export const SYNC_GROUP_ERROR = "sync_group_error"
export const SYNC_GROUP_FAILURE = "sync_group_failure"


// Filters
export const TOGGLE_COLUMN = "toggle_column"
export const SET_COLUMNS = "set_columns"
export const SET_LIMIT = "set_limit"
export const SET_TOTAL = "set_total"
export const SET_ORDER = "set_order"
export const SET_PAGE = "set_page"
export const SET_SYNC = "set_sync"
export const SET_SEARCH = "set_search"
export const SET_CODE = "set_code"
export const SET_GROUP = "set_group"
export const SET_SEMESTER = "set_semester"
export const SET_CLASSROOM_TYPE = "set_classroom_type"
export const SET_START_DATE_CLASSROOM = "set_start_date_classroom"
export const SET_END_DATE_CLASSROOM = "set_end_date_classroom"
export const SET_START_DATE_GROUP = "set_start_date_group"
export const SET_END_DATE_GROUP = "set_end_date_group"
export const SET_SEMESTERS = "set_semesters"
export const SET_CLASSROOM_TYPES = "set_classroom_types"
export const RESET_FILTERS = "reset_filters"
