import { createContext, useContext } from 'react'
import queryString from "query-string"
import * as env from 'config/env'

const getContext = () => {

  let ctx = {
    lang: "ca",                                   // laguange
    s: "[SESSION]",                               // session id
    userId: "[USERID]",                           // user id
    dateFormat: "dd/MM/yyyy",                     // Date format
    env: {}                                       // environment
  }

  // First get ctx from initial state if exists
  const state = window.__INITIAL_STATE__ || {}
  // Allow the passed state to be garbage-collected
  delete window.__INITIAL_STATE__

  ctx = {...ctx, ...state}

  // Second overwrite ctx from querystring if exists
  const parsed = queryString.parse(window.location.search)
  if (parsed.lang) ctx.lang = parsed.lang
  if (parsed.s) ctx.s = parsed.s
  if (parsed.userId) ctx.userId = parsed.userId
  if (parsed.demo) ctx.demo = parsed.demo === 'true' || parsed.demo === '1'

  if (ctx.lang === 'en') ctx.dateFormat = 'MM/dd/yyyy'


  // Environment
  const loc = window.location.hostname.toLowerCase()
  switch (loc){
    case process.env.REACT_APP_DOMAIN_TEST: // Test environment
      ctx.env = env.TEST
      break;
    case process.env.REACT_APP_DOMAIN_PRE:  // Pre environment
      ctx.env = env.PRE
      break;
    case process.env.REACT_APP_DOMAIN_PRO:  // Pro environment
      ctx.env = env.PRO 
      break;
    case process.env.REACT_APP_DOMAIN_DEMO: // DEMO environment
      ctx.env = env.DEMO 
      break;
    default:                                // Default environment (local development)
      ctx.env = env.DEV
  }

  return ctx

}

const SessionContext = createContext({})

function SessionProvider ({children}) {
  const context = getContext()
  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  )
}

function useSessionContext() {
  const context = useContext(SessionContext)
  if (context === undefined) {
    throw new Error('useSessionContext must be used within a SessionProvider')
  }
  return context
}

export { SessionProvider, useSessionContext }
