import {createContext, useContext, useMemo} from 'react'
import useMedia from 'use-media'

const MediaContext = createContext(null)

const mediaQueries = {
  sm: '(max-width: 767px)',
  md: '(min-width:768px) and (max-width: 991px)',
  lg: '(min-width:992px) and (max-width: 1279px)',
  xl: '(min-width: 1280px)'
}

function MediaProvider({children}) {
  const smView = useMedia(mediaQueries.sm)
  const mdView = useMedia(mediaQueries.md)
  const lgView = useMedia(mediaQueries.lg)
  const xlView = useMedia(mediaQueries.xl)
  const value = useMemo(() => ({smView, mdView, lgView, xlView}), [
    smView, mdView, lgView, xlView
  ])

  return (
    <MediaContext.Provider value={value}>
      {children}
    </MediaContext.Provider>
  )
}

function useMediaContext() {
  return useContext(MediaContext);
}

export {MediaProvider, useMediaContext}