import queryString from "query-string"
import { getHeaders, apiCall } from "services/api"

export const getFolders = ({ lang, env, order, order_by, id }) => {
  const headers = getHeaders(lang)
  const params = {
    lang: lang,
    sort: order_by + ',' + order
  }

  const url = process.env.REACT_APP_DEMO_MODE === '1' ? 
    `${env.consumer_api_base}/${process.env.REACT_APP_API_FOLDERS}` :
    `${env.consumer_api_base}/${process.env.REACT_APP_API_GROUPS}/${id}/${process.env.REACT_APP_API_FOLDERS}?${queryString.stringify(params)}`

  const request = new Request(
    url,
    {
      method: "GET",
      credentials: "include",
      headers: headers,
    }
  )
  return apiCall(request)
}
