import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

export const BreadcrumbItem = ({ children, to, active }) => {
    const cls = classNames("breadcrumb-item", { active: active })
    return (
        <li className={cls}>
            <Link to={to} aria-current={active ? 'page': null}  replace={active ? true : false} >{children}</Link>
        </li>
    )
}

const Breadcrumb = ({ children }) => {
    const {t} = useTranslation();
    return (
        <nav className="nav-breadcrumb" aria-label={t('common.breadcrumbs')}>
            <ol className="breadcrumb">
                {children}
            </ol>
        </nav>
    )
}

export default Breadcrumb