
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import * as c from 'config/constants'

const Limits = ({limit = c.PER_PAGE_DEFAULT, limits = c.PER_PAGE_LIMITS,  onChange = f => f}) => {

    const {t} = useTranslation()

    return (
      <>
        <span className="mr-1">{t("actions.show")}:</span>
        <ol className="list--unstyled list--inline list--limits">
          {limits.map((item) => {
            const active = item === limit;
            const cls = classNames("btnlink btnlink--regular", {
              disabled: active,
            });
            return (
              <li className={active ? "active" : ""} key={item}>
                <a
                  href="#/"
                  role="button"
                  className={cls}
                  aria-current={active ? "true" : "false"}
                  onClick={(e) => {
                    e.preventDefault();
                    onChange(item);
                  }}
                >
                  {item}
                </a>
              </li>
            );
          })}
        </ol>
      </>
    );
}

export default Limits