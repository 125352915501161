import { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import * as c from 'config/constants'

import ViewTransition from "components/ViewTransition"
import HistoricTable from "components/HistoricTable"
import Pagination from "components/Pagination"
import Limits from "components/Limits"
import Loading from 'components/Loading'

import { useHeaderDispatch } from 'context/HeaderContext'
import { useAppState, useAppDispatch } from 'context/AppContext'
import { useGroup } from 'hooks/useGroup'
import { useHistoric } from 'hooks/useHistoric'


const Historic = () => {
    
    const {t} = useTranslation()
    const { id } = useParams()
    const { group_name } = useAppState()
    const setHeader = useHeaderDispatch()
    const { groupState } = useGroup(id, !group_name)
    const { historicState, historicDispatch } = useHistoric(id)
    const appDispatch = useAppDispatch()

    useEffect(function () {
        const gname = group_name ? group_name : groupState.group.group_name ? groupState.group.group_name : null
        if (groupState.group.group_name){
            appDispatch({type: c.SET_GROUP, payload: { id, group_name: groupState.group.group_name }})
        }
        setHeader({
            title: `${t('app.historicTitle')}: ${gname}`,
            breadcrumb: [
                { to: '/', title: t('app.groupsTitle')},
                { to: `/groups/${id}`, title: gname },
                { to: `/groups/${id}/historic`, title: t('app.historicTitle')}
            ],
            config: true
        })
    }, [setHeader, t, id, group_name, groupState, appDispatch])

    const handleChangePage = (page, evt) => {
        evt.preventDefault()
        historicDispatch({ type: c.SET_PAGE, payload: page })
    }

    const handleChangeOrder = (orderBy, orderDir) => {
        historicDispatch({ type: c.SET_ORDER, payload: {order_by: orderBy, order: orderDir} })
    }

    const handleChangeLimit = (limit) => {
        historicDispatch({ type: c.SET_LIMIT, payload: limit })
    }


    const handleExpandCollapse = (hid) => {
        historicDispatch({ type: c.EXPAND_COLLAPSE, payload: hid })
    }

    if (historicState.isLoading && historicState.firstLoad) return <Loading />

    const { historic, size, page, total, order, order_by } = historicState

    return (
        <ViewTransition>
            <nav className="nav-options mb-2" aria-label={t('common.listOptions')}>
                <span className="result-total">
                    <strong>{total}</strong> {t('common.results')}
                </span>
                <Limits limit={size} onChange={handleChangeLimit} />
            </nav>
            <HistoricTable rows={historic} order={order} order_by={order_by} onChangeOrder={handleChangeOrder} onExpandCollapse={handleExpandCollapse} />
            <Pagination total={total} page={page} size={size} path={`/groups/${id}/historic`} onChangePage={handleChangePage} />
            { historicState.isLoading ? <Loading /> : null }
        </ViewTransition>
    )
}

export default Historic
