
import { useEffect, useReducer} from 'react'
import * as c from 'config/constants'

import { getFolders } from 'services/getFolders'
import { useSessionContext } from 'context/SessionContext'
import { useAppDispatch } from 'context/AppContext'

const initialState = {
  total: 0,
  order: c.FOLDERS_DEFAULT_ORDER,
  order_by: c.FOLDERS_DEFAULT_ORDER_BY,
  folders: [],
  firstLoad: true,
  isLoading: true,
  isError: false
}

const reducer = (state, action) => {
  switch (action.type) {
    case c.SET_ORDER:
      return {
        ...state,
        order_by: action.payload.order_by,
        order: action.payload.order
      }
    case c.FETCH_REQUEST:
      return {
        ...state,
        isLoading: true,
        isError: false
      }
    case c.FETCH_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
        firstLoad: false,
        
      }
    case c.FETCH_ERROR:
      return {
        ...state,
        isError: true,
        firstLoad: false,
        isLoading: false
      }
    case c.EXPAND_COLLAPSE:
      return {
          ...state,
          folders: state.folders.map(item => {
            return item.id + "" !== action.payload + ""
            ? item
            : { ...item, isExpanded: !item.isExpanded}
          })
      }
    default:
      return state
  }
}

export function useFolders(id) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { lang, env } = useSessionContext()
  const appDispatch = useAppDispatch()

  const { order, order_by } = state

  useEffect(
    function () {
      dispatch({ type: c.FETCH_REQUEST });
      getFolders({
        lang,
        env,
        order,
        order_by,
        id
      })
        .then((res) => {
          if (res.type && res.type === "error") {
            dispatch({ type: c.FETCH_ERROR, payload: res });
            appDispatch({ type: c.SET_MESSAGES, payload: [res] })
          } else {
            const folders = res.content.map(obj=> ({ ...obj, isExpanded: false }))
            dispatch({
              type: c.FETCH_SUCCESS,
              payload: {
                folders,
                total: res.totalElements
              }
            })
          }
        })
        .catch((error) => {
          console.log(error);
          dispatch({ type: c.FETCH_ERROR, payload: error })
          appDispatch({ type: c.SET_MESSAGES, payload: [error] })
        });
    },
    [lang, env, order, order_by, id, appDispatch]
  )
  
  return { foldersState: state, foldersDispatch: dispatch }
}