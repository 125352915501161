import { getHeaders, apiCall } from 'services/api'

export const getGroup = ({lang, env, id }) => {
  const headers = getHeaders(lang);

  const url = process.env.REACT_APP_DEMO_MODE === '1' ? 
    `${env.consumer_api_base}/${process.env.REACT_APP_DEMO_GROUP}` : 
    `${env.consumer_api_base}/${process.env.REACT_APP_API_GROUPS}/${id}`

  const request = new Request(
    url,
    {
      method: "GET",
      credentials: "include",
      headers: headers,
    }
  );
  return apiCall(request)
}