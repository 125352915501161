import { useTranslation } from "react-i18next"
import classNames from "classnames"

import * as c from 'config/constants'
import { useMediaContext } from 'context/MediaContext'

const leadZero = (num) => ((num && num < 10) ? '0' + num : num)

const calcPages = (total, page, size, slots = 5) => {
    if (total <= 0 || size <= 0) return {npages: 0, pages: []}

    const npages = Math.ceil(total/size)
    let pages = []
    var i;
    if(npages <= slots){
        for(i = 1; i <= npages; i++){
            pages.push({key: i, val: i - 1})
        }
    }else{
        for(i = 1; i <= slots; i++){
            if(i === 1 ) pages.push({key: i, val: i - 1})
            else if(i === slots ) pages.push({key: i, val: npages - 1})
            else pages.push({key: i, val: null})
        }
        if(slots === 7){
            if(page < 4) {
                for(i = 1; i<= 4; i++){
                    pages[i].val = i
                }
            }else if(page > npages - 4){
                for(i = 2; i<= 5; i++){
                    pages[i].val = npages - (7 - i)
                }
            }else{
                pages[2].val = page - 1
                pages[3].val = page
                pages[4].val = page + 1
            }
        } else {
            if(page > 2 && page < npages - 3){
                pages[2].val = page
            }else{
                if(page <= 2) {
                    pages[1].val = 1
                    pages[2].val = 2
                }else if(page >= npages - 3){
                    pages[2].val = npages - 3
                    pages[3].val = npages - 2
                }
            }
        }
    }
    return {npages, pages:[...pages]}
}

const Page = ({page, pos, current, last, path, onClick = f => f}) => {
    const {t} = useTranslation()
    const cls = classNames("pagination__item", { 
        'flex-grow-1': pos === last,
        'is-active': page === current
    })
    return (
      <li className={cls}>
      {page !== null ?
        <a
          href={"#" + path}
          role="button"
          className="pagination__link"
          aria-current={page === current}
          onClick={(evt) => onClick(page, evt)}
        >
          <span className="visually-hidden">{t("common.page")}&nbsp;</span>
          {leadZero(page + 1)}
        </a>
        :
        <span className="pagination__link"></span>
      }
      </li>
    )
}


const Pagination = ({ total = 0, page = 0, size = c.PER_PAGE_DEFAULT, path = '/', onChangePage = f => f}) => {
    const {t} = useTranslation()

    const { smView } = useMediaContext()
    const slots = smView ? 5 : 7
    const {npages, pages} = calcPages(total, page, size, slots)
    const last = Math.min(slots, pages.length)

    if (total <= 0 || size <= 0 || total <= size) return <div className="ruler m-3y"></div>

    const btnCls = classNames("btn btn--primary", { 
        'disabled': page === npages - 1
    })

    return (
      <ul
        className="pagination d-md-flex m-3y"
        aria-label={t("common.pagination")}
      >
        {pages.map((item, index) => (
          <Page
            key={"page-" + index}
            page={item.val}
            pos={item.key}
            last={last}
            path={path}
            current={page}
            onClick={(page, evt) => onChangePage(page, evt)}
          />
        ))}
        <li className="pagination__item pagination__item--next">
          <a
            href={"#" + path}
            className={btnCls}
            disabled={page === npages - 1}
            title={t("actions.next")}
            aria-label={t("actions.next")}
            rel="next"
            onClick={(evt) => {
              page === npages - 1
                ? evt.preventDefault()
                : onChangePage(page + 1, evt);
            }}
          >
            {t("actions.next")}
          </a>
        </li>
      </ul>
    )
}

export default Pagination