import { Alert } from "react-bootstrap"
import * as c from 'config/constants'

import ScrollToTopSmooth from "components/ScrollToTopSmooth"
import ViewTransition from "components/ViewTransition"
import { useAppState, useAppDispatch } from 'context/AppContext'

const AppMessages = () => {
  const { messages } = useAppState()
  const appDispatch = useAppDispatch()

  if (messages.length <= 0) return null;

  const handleCloseMessage = (index) => {
    appDispatch({ type: c.REMOVE_MESSAGE, payload: index })
  }

  return (
    <ViewTransition>
      <ScrollToTopSmooth />
      <div className="app-messages mb-4">
        {messages.map((msg, index) => {
          const type = msg.type ? msg.type : 'error'
          const icon = (type === 'error' || type === 'warning') ? 'icon--alert-full' : 'icon--info-full'
          return (
            <Alert
              dismissible
              variant={`-${type}-alternative`}
              onClose={() => handleCloseMessage(index)}
              className="app-msg mb-2"
              tabIndex="-1"
              key={index}
            >
              <div className="d-flex justify-content-start align-items-center">
                <span className={`icon ${icon} mr-3`} aria-hidden="true"></span>
                <div className="alert-content">
                  {msg.title && <h2 className="h5 mb-1">{msg.title}</h2>}
                  <p>
                    {msg.message}
                  </p>
                </div>
              </div>
            </Alert>
          )
        })}
      </div>
    </ViewTransition>
  )
}

export default AppMessages
