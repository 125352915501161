import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { useAppDispatch } from 'context/AppContext'
import * as c from 'config/constants'

export default function CleanMessages() {
  const { pathname } = useLocation()
  const appDispatch = useAppDispatch()
  useEffect(() => {
    appDispatch({ type: c.RESET_MESSAGES })
  }, [pathname, appDispatch])

  return null
}