import { useState, useContext, createContext } from 'react'

const HeaderStateContext = createContext()
const HeaderDispatchContext = createContext()

function HeaderProvider({ children }) {
  const [state, dispatch] = useState({
    title: 'Gestió de carpetes i grups de Google', 
    breadcrumb: [],
    config: true
  })
  return (
    <HeaderStateContext.Provider value={state}>
      <HeaderDispatchContext.Provider value={dispatch}>
        {children}
      </HeaderDispatchContext.Provider>
    </HeaderStateContext.Provider>
  )
}

function useHeaderState() {
  const context = useContext(HeaderStateContext)
  if (context === undefined) {
    throw new Error('useHeaderState must be used within a HeaderProvider')
  }
  return context
}

function useHeaderDispatch() {
  const context = useContext(HeaderDispatchContext)
  if (context === undefined) {
    throw new Error('useHeaderDispatch must be used within a HeaderProvider')
  }
  return context
}

export { HeaderProvider, useHeaderState, useHeaderDispatch }