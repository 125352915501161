import { useEffect } from 'react'
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom"
import * as c from 'config/constants'

import ViewTransition from "components/ViewTransition"
import FoldersTable from "components/FoldersTable"
import Loading from 'components/Loading'

import { useHeaderDispatch } from 'context/HeaderContext'
import { useAppState, useAppDispatch } from 'context/AppContext'
import { useGroup } from 'hooks/useGroup'
import { useFolders } from 'hooks/useFolders'

const Folders = () => {

    const {t} = useTranslation()
    const { id } = useParams()
    const { group_name } = useAppState()
    const setHeader = useHeaderDispatch()
    const { groupState } = useGroup(id, !group_name)
    const { foldersState, foldersDispatch } = useFolders(id)
    const appDispatch = useAppDispatch()

    useEffect(function () {
        const gname = group_name ? group_name : groupState.group.group_name ? groupState.group.group_name : null
        if (groupState.group.group_name){
            appDispatch({type: c.SET_GROUP, payload: { id, group_name: groupState.group.group_name }})
        }
        setHeader({
            title: `${t('app.foldersTitle')}: ${gname}`,
            breadcrumb: [
                { to: '/', title: t('app.groupsTitle')},
                { to: `/groups/${id}`, title: gname },
                { to: `/groups/${id}/folders`, title: t('app.foldersTitle')}
            ],
            config: true
        })
    }, [setHeader, t, id, group_name, groupState, appDispatch])

    const handleChangeOrder = (orderBy, orderDir) => {
        foldersDispatch({ type: c.SET_ORDER, payload: {order_by: orderBy, order: orderDir} })
    }

    const handleExpandCollapse = (hid) => {
        foldersDispatch({ type: c.EXPAND_COLLAPSE, payload: hid })
    }

    if (foldersState.isLoading && foldersState.firstLoad) return <Loading />

    const { folders, total, order, order_by } = foldersState

    return (
      <ViewTransition>
        <div className="nav-options only-results mb-2">
          <span className="result-total">
            <strong>{total}</strong> {t("common.results")}
          </span>
        </div>
        <FoldersTable
          rows={folders}
          order={order}
          order_by={order_by}
          onChangeOrder={handleChangeOrder}
          onExpandCollapse={handleExpandCollapse}
        />
        {foldersState.isLoading ? <Loading /> : null}
      </ViewTransition>
    );
}

export default Folders;
