import { memo } from 'react'
import { useTranslation } from "react-i18next"
import logo from "images/logo-uoc-default.png"


const BrandHeader = () => {
    const {t} = useTranslation()
    return (
        <section className="header brand-header-light">
            <div className="header-container clearfix">
            <div className="row">
                <div className="col-xs-12 col-md-4 col-xl-3">
                <span className="top-logo">
                    <img src={logo} alt="Logo UOC" />
                </span>
                <p className="top-slogan ruler ruler--secondary ruler--double">
                    Universitat Oberta <br aria-hidden="true" /> de Catalunya
                </p>
                </div>
                <div className="hidden-xs hidden-sm col-md-8 col-xl-9">
                <div className="top-title-wrapper ruler ruler--secondary ruler--double">
                    <h1 className="top-title">{t('app.title')}</h1>
                </div>
                </div>
            </div>
            </div>
        </section>
    )
}

export default memo(BrandHeader)