import {Suspense} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
import {HashRouter as Router, Route, Switch} from "react-router-dom"
import {useTranslation} from "react-i18next"

import Header from "components/Header"
import Loading from "components/Loading"
import NotFound from "views/NotFound"
import Groups from "views/Groups"
import Group from "views/Group"
import Config from "views/Config"
import Historic from "views/Historic"
import Folders from "views/Folders"
import ScrollToTop from 'components/ScrollToTop'
import AppMessages from 'components/AppMessages'
import CleanMessages from 'components/CleanMessages'
import MainContainer from "components/MainContainer"

import {SessionProvider} from "context/SessionContext"
import {HeaderProvider} from "context/HeaderContext"
import {MediaProvider} from "context/MediaContext"
import {AppProvider} from "context/AppContext"
import {GroupsProvider} from "context/GroupsContext";
import Authentication from "./components/Authentication";

const Main = () => {
    const {t, i18n} = useTranslation();

    return (
        <>
            <Helmet
                htmlAttributes={{lang: i18n.language}}
                title={t('app.title')}
            />
            <Header/>
            <AppProvider>
                <MainContainer>
                    <CleanMessages/>
                    <AppMessages/>
                    <MediaProvider>
                        <GroupsProvider>
                            <Switch>
                                <Route exact path="/groups/:id/historic">
                                    <Historic/>
                                </Route>
                                <Route exact path="/groups/:id/folders">
                                    <Folders/>
                                </Route>
                                <Route exact path="/groups/:id">
                                    <Group/>
                                </Route>
                                <Route exact path="/config">
                                    <Config/>
                                </Route>
                                <Route exact path={["/", "/groups"]}>
                                    <Groups/>
                                </Route>
                                <Route path="*">
                                    <NotFound/>
                                </Route>
                            </Switch>
                        </GroupsProvider>
                    </MediaProvider>
                </MainContainer>
            </AppProvider>
        </>
    )
}

const App = () => {
    return (
        <Suspense fallback={<Loading/>}>
            <SessionProvider>
                <Authentication>
                    <HelmetProvider>
                        <HeaderProvider>
                            <Router>
                                <ScrollToTop/>
                                <Main/>
                            </Router>
                        </HeaderProvider>
                    </HelmetProvider>
                </Authentication>
            </SessionProvider>
        </Suspense>
    )
}

export default App;
